import React from 'react';
import Header from '../layout/header/header';
import Sidebar from '../layout/sidebar/sidebar';
import OrderC from '../layout/center/order';
function Order(props) {
    return (
        <div className='container' >
        <Header/>
        <Sidebar/>
        <OrderC/>
      </div>
    );
}

export default Order;