// src/components/SkeletonLoader.js
import React from 'react';
import './skeletonLoader.css';

function SkeletonLoader() {
  return (
    <div className="skeleton">
      <div className="skeleton-header"></div>
      <div className="skeleton-sidebar"></div>
      <div className="skeleton-content"> </div>
      <div className="skeleton-righbar"> </div>
    </div>
  );
}

export default SkeletonLoader;
