import React, { useState,useRef,useEffect } from "react";
import "../../assets/styles/style.css";
import add from "../../assets/icons/braider.svg";
import RightbarMenu from "../rightbar/rightbarMenu";
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../services/AuthServices";
import { colors } from "../../assets/config/color";
import { FaEdit, FaTrash, FaPlus, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ConfirmDialog from "../../components/CustomDialog/ConfirmDialog";
import BraidsForm from "../../components/BraidsForm";
function MenuCenter(props) {
  const toastId = useRef(null);
  const [hoveredTypes, setHoveredTypes] = useState([]);
  const [userId ,setUserId]=useState();
  const [token, setToken]=useState();
  const [categories,setCategories]=useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [allTypes, setAllTypes] = useState([]);
const getUserData =()=>{
  const tok =  localStorage.getItem('token');
  const id = localStorage.getItem('id');
  setToken(tok);
  setUserId(id);
}
  const handleCategories = async()=>{
    try{
      if (token &&  userId ) {
        const response = await AuthService.getBraidsCategories(userId,token);
    if (response.data) {
      setCategories(response.data);
    }
    else{
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(response.error);
      }
    }
      }
      else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token ou User ID manquant");
        }
      }
    
    }
    catch (error){
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
      }
    }
  }
  const handleTypes = async()=>{
    try{
      if (token &&  userId ) {
        const response = await AuthService.getAllTypes(userId,token);
    if (response.data) {
      setAllTypes(response.data);
    }
    else{
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(response.error);
      }
    }
      }
      else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token ou User ID manquant");
        }
      }
    
    }
    catch (error){
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
      }
    }
  }
  const [showSubMenu, setShowSubMenu] = useState(false);
  const rowItemRef = useRef(null);
  const timerRef = useRef(null);

  const handleMouseEnter = (index,categoryId) => {
    clearTimeout(timerRef.current);
    setHoveredIndex(index);
        const filteredTypes = allTypes.filter(type => type.type_categories_id === categoryId);
        setHoveredTypes(filteredTypes);
        setShowSubMenu(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setShowSubMenu(false);
    }, 2000); 
  };
  const handleSubMenuMouseEnter = () => {
    clearTimeout(timerRef.current);
    setShowSubMenu(true);
  };

  const handleSubMenuMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setShowSubMenu(false);
    },0);
  };
  useEffect(() => {
    getUserData();
  }, []); 
  
  useEffect(() => {
    if (userId && token) {
      handleCategories();
      handleTypes();
      handleBraids();
      handleUsers();
    }
  }, [userId, token]);
  
  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      
    };
  }, []);
  useEffect(() => {
    if (allTypes && allTypes.length > 0) {
      setBraidTypeId(allTypes[0].id);
    }
  }, [allTypes]);
  
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(1000);
  const [selectedCategory, setSelectedCategory] = useState(null);
    const toggleCategory = (index,categoryId) => {
        setSelectedCategory(selectedCategory === index ? null : index);
        const filteredTypes = allTypes.filter(type => type.type_categories_id === categoryId);
        setHoveredTypes(filteredTypes);
    };
  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const handleAddTableClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setShowButton(true);
    setIsModalOpen(false);
    setSelected(null);
  };
const [selected,setSelected]=useState(null);

const addCategorie=()=>{
  setShowButton(false);
  setSelected(1);
  }
  const addBraid=()=>{
    setShowButton(false);
    setSelected(2);
    }
  const [showButton,setShowButton]=useState(true);
  const [categorieName,setCategorieName]=useState('');
  const [categorieDescription,setcategorieDescription]=useState();
  const categorieNameChange=(event)=>{
    setCategorieName(event.target.value);
  }
  const categorieDescriptionChange=(event)=>{
    setcategorieDescription(event.target.value);
  }
  const cancelButton=()=>{
    setShowButton(true);
    setSelected(null);
    setSelectedImage(null)
  }

const handleAddCategorie = async()=>{
  try{
    if (!categorieName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Choose the categorieName, it's require!");
      }
      return;
    }
      const response = await AuthService.addNewCategories(userId,token,categorieName,categorieDescription)

  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    setCategorieName('');
    setcategorieDescription('');
    await handleCategories();
    await handleTypes();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error)
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
};
const handleUpdateCategorie = async()=>{
  try{
      const response = await AuthService.updateCategories(userId,token,confirmEdit.id,confirmEdit.name,confirmEdit.description)
  
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    setConfirmEdit(null)
    setShowButton(true);
    await handleCategories();
    await handleTypes();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error)
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
};
const handleDeleteCategorie = async(_id)=>{
  try{
      const response = await AuthService.deleteCategories(userId,token,_id);
  
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    setConfirmDelete(null);
    setShowButton(true);
    await handleCategories();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error);
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
}
const handleAddType = async()=>{
  try{
      const response = await AuthService.addNewType(userId,token,confirmAddType.name,confirmAddType.description,confirmAddType.id)
 
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    setconfirmAddType(null)
    setShowButton(true);
    await handleTypes();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error)
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
};
const handleUpdateType = async()=>{
  try{
      const response = await AuthService.updateType(userId,token,confirmEditType.id,confirmEditType.name,confirmEditType.description,confirmEditType.type_categories_id)
  
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    setConfirmEditType(null)
    setShowButton(true);
    await handleTypes();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error)
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
};
const handleDeleteType = async(_id)=>{
  try{
      const response = await AuthService.deleteType(userId,token,_id);
 
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    setConfirmDeleteType(null);
    setShowButton(true);
    await handleTypes();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error);
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
}
const [confirmDelete, setConfirmDelete] = useState(null);
const [confirmEdit,setConfirmEdit]= useState(null);
const [confirmAddType,setconfirmAddType]=useState(null);
const [confirmEditType,setConfirmEditType]=useState(null);
const [confirmDeleteType,setConfirmDeleteType]=useState(null);

const handleDeleteClick = (category) => {
  setConfirmDelete({
    message: `Are you sure you want to delete the style "${category.name}"?`,
    onConfirm: () => handleDeleteCategorie(category.id)
  });
};
const handleDeleteTypeClick = (type) => {
  setConfirmDeleteType({
    message: `Are you sure you want to delete this length "${type.name}"?`,
    onConfirm: () => handleDeleteType(type.id)
  });
};
const handleEditClick = (category) => {
  setShowButton(false);
  setConfirmEdit({
    name: category.name,
    description: category.description,
    id: category.id
  });
};
const handleAddTypeClick =  (category) => {
  setShowButton(false);
  setconfirmAddType({
    category: category.name,
    id: category.id,
  });
};
const handleEditTypeClick = (type) => {
  setShowButton(false);
  setConfirmEditType({
    id:type.id,
    name: type.name,
    description: type.description,
    type_categories_id:type.type_categories_id
  });
};
const handleCancelDelete = () => {
  setConfirmDelete(null);
};
const handleCancelDeleteTYPE = () => {
  setConfirmDeleteType(null);
};
const handleCancelEdit = () => {
  setConfirmEdit(null);
  setShowButton(true);
};
const handleCancelAddType = () => {
  setconfirmAddType(null);
  setShowButton(true);
};
const handleCancelEditType = () => {
  setConfirmEditType(null);
  setShowButton(true);
};
const [selectedImage, setSelectedImage] = useState(null);
const [imagePreview, setImagePreview] = useState(null);
const handleImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      const base64String= reader.result.replace('data:', '').replace(/^.+,/, '');
      setSelectedImage(base64String);
    };
    reader.readAsDataURL(file); 
  }
};
const [isPromo, setIsPromo] = useState(false);
const [promoPrice, setPromoPrice] = useState(null);
const [braidDescription,setBraidDescription]=useState('');
const [priceNormal,setPriceNormal]=useState();
const [selectedStatut,setSelectedStatut]=useState('available');
const [braid_type_id,setBraidTypeId]=useState();
const [allBraids,setAllBraids]=useState([]);
const [loading, setLoading] = useState(false);
const handleSelectedStatutChange=(statut) =>{
  setSelectedStatut(statut);
}

  const handlePromoCheckboxChange = (event) => {
    setIsPromo(event.target.checked);
    if (!event.target.checked) {
      setPromoPrice();
    }
  };

  const handlePromoPriceChange = (event) => {
    setPromoPrice(event.target.value);
  };
  const handleBraidDescriptionChange = (event) => {
    setBraidDescription(event.target.value);
  };
  const handlePriceNormalChange = (event) => {
    setPriceNormal(event.target.value);
  };
  const handleBraidNameChange = (event) => {
    setBraidTypeId(event.target.value);
  };
  const handleAddBraids = async()=>{
    try{
      if (!braid_type_id) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please Choose the name of the Braid, it's require!");
        }
        return;
      }
      if (!priceNormal || priceNormal <= 0) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("PriceNormal is required and must be a positive number.");
        }
        return;
      }
      if (isPromo) {
        if (!promoPrice || promoPrice <= 0) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Price Promo is required and must be a positive number.");
        }
        return;
      }
      if (priceNormal <= promoPrice) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Promotional price must be less than the normal price and must be a positive number.");
        }
        return;
      }
      
      
      }
      if (!selectedImage) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("An Image is required ");
        }
        return;
      }
      if(!user){
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Assign an employer to the Braid ");
          }
      }
      if (!startTime) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Start time is required ");
          }
        
      }

    const response = await AuthService.addBraid(userId,token,braid_type_id,user,braidDescription,priceNormal,promoPrice,isPromo,selectedStatut,selectedImage,startTime)
    if (response.message) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(response.message);
      }
      setSelected(null)
      setSelectedImage(null);
      setShowButton(true);
      await handleBraids();
    }
    else{
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(response.error);
      }
    }
    
    }
    catch (error){
      console.log(error)
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
      }
    }
  };
  const handleBraids = async()=>{
    setLoading(true);
    try{
      if (token &&  userId ) {
        const response = await AuthService.getAllBraids(userId,token);
    if (response.data) {
      setAllBraids(response.data);
    }
    else{
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(response.error);
      }
    }
      }
      else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token ou User ID manquant");
        }
      }
    
    }
    catch (error){
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
      }
    }
    finally{
      setLoading(false);
    }
  }
  const [selectedDish, setSelectedDish] = useState(null);

  useEffect(() => {
    if (allBraids && allBraids.length > 0 && !selectedDish) {
      setSelectedDish(allBraids[0]);
      setFilteredBraids(allBraids);
    }
  }, [allBraids]);

  const handleDishClick = (id) => {
    const selected = allBraids.find(braid => braid.id === id);
    setSelectedDish(selected);
  };
  const [filterdBraids,setFilteredBraids]=useState([]);
  const handleFilter = (idCategorie) => {
    const filtered = allBraids.filter(braid => {
     return braid.braid_type_id === idCategorie;});
    setFilteredBraids(filtered);
    };
const filterType=(type)=>{
  const filtered = allBraids.filter(braid => {
    return (braid.braid_type_id === type.id && braid.name===type.name);});
    setFilteredBraids(filtered);
}
    const handleResetFilter = () => {
      setFilteredBraids(allBraids);
      };
      const [startTime, setStartTime] = useState("00:00");
    
      const incrementTime = (time, setTime) => {
        const [hours, minutes] = time.split(':').map(Number);
        let newMinutes = minutes + 1;
        let newHours = hours;
    
        if (newMinutes === 60) {
          newMinutes = 0;
          newHours = (newHours + 1) % 24;
        }
    
        setTime(`${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`);
      };
    
      const decrementTime = (time, setTime) => {
        const [hours, minutes] = time.split(':').map(Number);
        let newMinutes = minutes - 1;
        let newHours = hours;
    
        if (newMinutes === -1) {
          newMinutes = 59;
          newHours = (newHours - 1 + 24) % 24;
        }
    
        setTime(`${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`);
      };
      const style ={
        border:'none',backgroundColor:'#fff',padding:'0',paddingLeft:'8px',color: '#9f9f9e'
      }
const [users,setUsers]=useState([]);
const [user, setUser] = useState("");
const handleUsers = async()=>{
  try{
    if (token) {
      const response = await AuthService.getEmployers(token);
  if (response.data) {
    setUsers(response.data);
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
    }
    else {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Token ou User ID manquant");
      }
    }
  
  }
  catch (error){
    console.log(error);
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Error Where filtering Employers" );
    }
  }
  
}
const handleUserChange = (event) => {
  setUser(event.target.value);
};
  return (
    <div className={`${isSmallScreen ? 'main-contentH1' : 'main-contentH'}`}>
              {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal">
            <div className="row">
                  <div className="form-group">
                    <label>Choice an option</label>
                  </div>
                  <div className="form-group">
                    <button className="close-button" onClick={handleCloseModal}>
                      X
                    </button>
                  </div>
            </div>
            
            {
              showButton?(
                <>
              <div className="row"> 
            <div > <button className="button" onClick={addCategorie}>ADD A NEW STYLE </button></div>
            <div > <button className="button" style={{opacity:categories.length <= 0?0.2:1}} disabled={categories.length <= 0} onClick={addBraid}>ADD NEW BRAID</button></div>
            </div>
            <div style={{marginTop:20,fontSize:22,color:colors.gold,fontWeight:'bold'}}>Items:{categories.length}</div>
                <div className="category-list">
                {categories.map((category, index) => {
                  return(
                  <div key={index} className="category-item">
                        <div className="category-header">
                            <span>{category.name}</span>
                            <div className="crud-icons">
                                <FaEdit title="Edit" className="icon-edit" onClick={()=> handleEditClick(category)} />
                                <FaTrash title="Delete" className="icon-delete"onClick={()=> handleDeleteClick(category)} />
                                <FaPlus title="Add Type" className="icon-add" onClick={()=>handleAddTypeClick(category)} />
                                {allTypes.length!== 0 && allTypes.some(type => type.type_categories_id === category.id) && ( 
                          <div className="toggle-button" onClick={() => toggleCategory(index,category.id)}>
                            {selectedCategory === index  ? <FaChevronUp color='#a9a9a9' /> : <FaChevronDown color='#a9a9a9' />}
                               </div>
                                      )}
                            </div>
                        </div>
                        {selectedCategory === index && (
                            <div className="category-types">
                            {hoveredTypes.map((type, idx) => (
                                <div key={idx} className="type-item">
                                    {type.name}
                                    <div className="type-actions">
                                        <FaEdit className="icon-small icon-edit" title="Edit" onClick={()=>handleEditTypeClick(type)} />
                                        <FaTrash className="icon-small icon-delete" title="Delete" onClick={()=>handleDeleteTypeClick(type)} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        )}
                    </div>
                  );
                    
})}
            </div>
             </>
              ):null
            }
            {confirmDelete && (
              <ConfirmDialog
                message={confirmDelete.message}
                onConfirm={confirmDelete.onConfirm}
                onCancel={handleCancelDelete}
                texConfirm='YES'
                textCancel='NO'
              />
            )}
            {confirmDeleteType && (
              <ConfirmDialog
                message={confirmDeleteType.message}
                onConfirm={confirmDeleteType.onConfirm}
                onCancel={handleCancelDeleteTYPE}
                texConfirm='YES'
                textCancel='NO'
              />
            )}
            {
              confirmEdit &&(
                <div className="column" style={{justifyContent:'center',alignItems:'center'}}>
                <div className="form-group">
                    <label style={{color:colors.gold}}>Edit Style "({confirmEdit.name})"</label>
                  </div>
                <div className="row">
                <div className="form-group">
                <label for="name" style={{marginBottom:'16px'}}>NAME<span style={{color:'red'}}>*</span></label>
                <input value={confirmEdit.name} type="text" id="name" placeholder="categorie name" onChange={(event) => setConfirmEdit({ ...confirmEdit, name: event.target.value })}  />
              </div>
              <div className="form-group"style={{marginLeft:'12px',}}>
                <label for="name" style={{marginBottom:'16px'}}>DESCRIPTION</label>
                <input value={confirmEdit.description||''} type="text" id="name" placeholder="categorie description" onChange={(event) => setConfirmEdit({ ...confirmEdit, description: event.target.value })} />
              </div>
              </div>
              <div className="rowL">
              <div className="button-container"><button className="button" onClick={handleUpdateCategorie}>UPDATE</button></div>
              <div className="button-container"><button className="button" style={{marginLeft:'12px',backgroundColor:colors.withGold}} onClick={handleCancelEdit}>CANCEL</button></div>
              </div>
              </div>
              )
            }
            {
              confirmAddType &&(
                <div className="column" style={{justifyContent:'center',alignItems:'center'}}>
                <div className="form-group">
                    <label style={{color:colors.gold}}>Add New Length for "{confirmAddType.category}"</label>
                  </div>
                <div className="row">
                <div className="form-group">
                <label for="name" style={{marginBottom:'16px'}}>NAME<span style={{color:'red'}}>*</span></label>
                <input type="text" id="name" placeholder="categorie name" onChange={(event) => setconfirmAddType({ ...confirmAddType, name: event.target.value })}  />
              </div>
              <div className="form-group"style={{marginLeft:'12px',}}>
                <label for="name" style={{marginBottom:'16px'}}>DESCRIPTION</label>
                <input  type="text" id="name" placeholder="categorie description" onChange={(event) => setconfirmAddType({ ...confirmAddType, description: event.target.value })} />
              </div>
              </div>
              <div className="rowL">
              <div className="button-container"><button className="button" onClick={handleAddType}>ADD</button></div>
              <div className="button-container"><button className="button" style={{marginLeft:'12px',backgroundColor:colors.withGold}} onClick={handleCancelAddType}>CANCEL</button></div>
              </div>
              </div>
              )
            }
            {
              confirmEditType &&(
                <div className="column" style={{justifyContent:'center',alignItems:'center'}}>
                <div className="form-group">
                    <label style={{color:colors.gold}}>Edit Length "({confirmEditType.name})"</label>
                  </div>
                <div className="row">
                <div className="form-group">
                <label for="name" style={{marginBottom:'16px'}}>NAME<span style={{color:'red'}}>*</span></label>
                <input value={confirmEditType.name} type="text" id="name" placeholder="categorie name" onChange={(event) => setConfirmEditType({ ...confirmEditType, name: event.target.value })}  />
              </div>
              <div className="form-group"style={{marginLeft:'12px',}}>
                <label for="name" style={{marginBottom:'16px'}}>DESCRIPTION</label>
                <input value={confirmEditType.description||''} type="text" id="name" placeholder="categorie description" onChange={(event) => setConfirmEditType({ ...confirmEditType, description: event.target.value })} />
              </div>
              </div>
              <div className="rowL">
              <div className="button-container"><button className="button" onClick={handleUpdateType}>UPDATE</button></div>
              <div className="button-container"><button className="button" style={{marginLeft:'12px',backgroundColor:colors.withGold}} onClick={handleCancelEditType}>CANCEL</button></div>
              </div>
              </div>
              )
            }
            {
              selected === 1 &&(
                <div className="column" style={{justifyContent:'center',alignItems:'center'}}>
                <div className="form-group">
                    <label style={{color:colors.gold}}>Add Categorie option</label>
                  </div>
                <div className="row">
                <div className="form-group">
                <label for="name" style={{marginBottom:'16px'}}>NAME<span style={{color:'red'}}>*</span></label>
                
                <input value={categorieName} type="text" id="name" placeholder="categorie name" onChange={(event)=>categorieNameChange(event)} />
              </div>
              <div className="form-group"style={{marginLeft:'12px',}}>
                <label for="name" style={{marginBottom:'16px'}}>DESCRIPTION:</label>
                <input value={categorieDescription} type="text" id="name" placeholder="categorie description" onChange={(event)=>categorieDescriptionChange(event)} />
              </div>
              </div>
              <div className="rowL">
              <div className="button-container"><button disabled={categorieName.trim() === ''} onClick={handleAddCategorie} className="button" >ADD</button></div>
              <div className="button-container"><button className="button" style={{marginLeft:'12px',backgroundColor:colors.withGold}} onClick={cancelButton}>CANCEL</button></div>
              </div>
              </div>
              )
            }
            {
              selected === 2 &&(
                <div className="column">
                <div className="rowL" style={{marginBottom:'16px'}} >
                    <label style={{color:colors.gold}}>Add Braid option</label>
                </div>
                <div className="row" style={{width:'100%'}}>
                <div className="form-group" style={{width:'45%'}} >
                <label for="name" style={{marginBottom:'16px'}}>NAME<span style={{color:'red'}}>*</span></label>
                <select value={braid_type_id} onChange={(event)=>handleBraidNameChange(event)}>
                {allTypes.length !== 0 &&
  allTypes.map((type, index) => {
    const categorie = categories.find((categ) => categ.id === type.type_categories_id);
    return (
        <option key={index} value={type.id}>{type.name}({categorie && categorie.name})</option>
      
    );
  })
}

                
                </select>
                  </div>
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>DESCRIPTION</label>
                <input value={braidDescription} type="text" id="name" placeholder="categorie description" onChange={(event)=>handleBraidDescriptionChange(event)} />
              </div>
              </div>
              <div className="row">
                <div className="form-group" style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Price Normal<span style={{color:'red'}}>*</span></label>
                <input min={1} value={priceNormal} type="number" id="name" placeholder="240$" onChange={(event)=>handlePriceNormalChange(event)} />
              </div>

              <div className="form-group" style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px',opacity:isPromo?1:0.4}}>Price Promo<span style={{color:'red'}}>*</span></label>
                <input 
                min={1}
                       type="number" 
                       placeholder="Promo Price" 
                       value={promoPrice} 
                       onChange={handlePromoPriceChange} 
                       disabled={!isPromo} 
                       style={{opacity:isPromo?1:0.4}}
                     /> </div>
              </div>
              <div className="row">
              <div className="form-group">
                <label for="name" style={{marginBottom:'16px'}}>Time</label>
                <div className="time-input-container">
                          <input
                            type="time"
                            id="start-time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            style={{ margin: "0 5px" }}
                          />
                          <div style={{display:'flex', flexDirection:'column'}}>
                          <button
                          style={style}
                            onClick={() =>
                              incrementTime(startTime, setStartTime)
                            }
                          >
                            ▲
                          </button>
                          <button
                          style={style}
                            onClick={() =>
                              decrementTime(startTime, setStartTime)
                            }
                          >
                            ▼
                          </button>
                          </div>
                           
                        </div>
              </div>
                <div className="form-group" style={{width:'45%'}} >
                <label for="name" style={{marginBottom:'16px'}}>EMPLOYERS<span style={{color:'red'}}>*</span></label>
                <select value={user} onChange={(event)=>handleUserChange(event)}>
                  {users.length!== 0 &&
                    users.map((user,index)=>(
                      <option key={index} value={user.id} >{user.firstName+' '+ user.lastName}</option>
                    ))
                  }
                
                </select>
                  </div>
             
              </div>
              <div className="rowS">
              <div className="form-group" style={{width:'45%'}}>
              <label>
        <input 
          type="checkbox" 
          checked={isPromo} 
          onChange={handlePromoCheckboxChange}
          value={isPromo}
        />
        Is Promo?
      </label>
      </div>
      <div className="column" style={{width:'45%'}}>
          <label for="name" >Statut<span style={{color:'red'}}>*</span></label>
          <div className="column">
            <div ><input  type="checkbox" value='available' checked={selectedStatut==='available'}  onChange={()=>handleSelectedStatutChange('available')}/>Available</div>
            <div><input  type="checkbox" value='out_of_stock' checked={selectedStatut==='out_of_stock'}  onChange={()=>handleSelectedStatutChange('out_of_stock')} />Out of stock</div>
            <div><input  type="checkbox" value='discontinued' checked={selectedStatut==='discontinued'}  onChange={()=>handleSelectedStatutChange('discontinued')} />Discontinued</div>
      </div>
      </div>
      </div>
      <div className="rowL">
      <label htmlFor="imageUpload">Upload Image<span style={{color:'red'}}>*</span>
      <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" onChange={(event) => handleImageUpload(event)} /></label>
      </div>
      {
       selectedImage && (
    <div className="image-preview">
      <img src={imagePreview} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} />
    </div>
         )
         } 
              <div className="rowL">
              <div className="button-container"><button className="button" onClick={handleAddBraids}>ADD</button></div>
              <div className="button-container"><button className="button" style={{marginLeft:'12px',backgroundColor:colors.withGold}} onClick={cancelButton}>CANCEL</button></div>
              </div>
 
              </div>
              )
            }
            </div>
          </div>
        )}
    <div className={`${isSmallScreen ? 'column' : 'row'}`} style={{flex:1}}>
    <div className="main-content" style={{width:isSmallScreen?'100%':'65%'}}>
      <div className="padding">
        <div className="menuItem">
        <div className="rowItem itemM" ref={rowItemRef}>
        <span className={selectedItem === 1000 ? "selected" : ""} onClick={() => {handleItemClick(1000);handleResetFilter()}}>All</span>
          {
            categories.length>0?categories.map((item, index) => (
              <span 
              key={item.id}
              onMouseEnter={() => handleMouseEnter(index,item.id)}
              onMouseLeave={handleMouseLeave}
              className={selectedItem === index ? "selected" : ""}
              onClick={() => {handleItemClick(index); handleFilter(item.id)}}>
                {item.name}
                {allTypes.length!== 0 && allTypes.some(type => type.type_categories_id === item.id) && ( 
            <span style={{ marginLeft: '5px', fontSize: '10px', color: '#d9d9d9' }}>
              {hoveredIndex === index || hoveredIndex === null ? '▲' : '▼'}
            </span>
          )}
              </span>
          )):<p>Aucune catégorie disponible</p>
          }
        </div>
        {showSubMenu && hoveredTypes && hoveredTypes.length > 0  && (
        <div
          className="subMenu"
          onMouseEnter={handleSubMenuMouseEnter}
          onMouseLeave={handleSubMenuMouseLeave}
          style={{
            position: 'absolute',
            top: rowItemRef.current ? rowItemRef.current.offsetTop + rowItemRef.current.offsetHeight : 0,
            left: rowItemRef.current ? rowItemRef.current.children[hoveredIndex + 1]?.getBoundingClientRect().left - rowItemRef.current.getBoundingClientRect().left : 0,
     }}
        >
          {hoveredTypes.map((subItem, subIndex) => (
            <span key={subIndex} onClick={()=>filterType(subItem)}>{subItem.name}</span>
          ))}
        </div>
      )}
        </div>
        
        <div className="column">
          <div className='rowM'>
          <div
              className="addBraiding" style={{width:isSmallScreen?"300px":'',padding:isSmallScreen?'18px':'8px'}}
              onClick={
                handleAddTableClick
              }
            >
              <img src={add} alt="" />
              <div className="addBraid">
                <span>+</span>
                </div>
              <h6>Add</h6>
              <h6>Braiding</h6>
            </div>
            {loading ? (
            <div className="loader"></div>
        ) : (
            filterdBraids.length > 0 ? (
              <BraidsForm 
        allBraids={filterdBraids} 
        selectedDish={selectedDish} 
        onDishClick={handleDishClick} 
      />
            ) : (
                <p>No braids available.</p>
            ))}
            
           
          </div>
        </div>
      </div>
    </div>
    <div>
      
    </div>
    <RightbarMenu ChoiceBraid={selectedDish} />
    
    
    </div>
    <ToastContainer limit={1} position="top-center" />
    </div>
    
  );
}

export default MenuCenter;
