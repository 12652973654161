import React from 'react';

function booking({fill}) {
    return (
       
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="24" height="24" viewBox="0 0 136.000000 144.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,144.000000) scale(0.100000,-0.100000)"
fill={fill} stroke="none">
<path d="M377 1282 c-10 -10 -17 -35 -17 -55 l0 -37 -57 0 c-145 0 -263 -122
-263 -272 l0 -48 640 0 640 0 0 48 c0 150 -118 272 -262 272 l-58 0 0 38 c0
43 -23 72 -58 72 -27 0 -52 -36 -52 -77 l0 -33 -210 0 -210 0 0 33 c0 65 -56
101 -93 59z"/>
<path d="M42 493 c3 -310 5 -320 78 -393 77 -77 61 -75 560 -75 499 0 483 -2
560 75 73 73 75 83 78 393 l3 277 -641 0 -641 0 3 -277z m952 95 c9 -12 16
-26 16 -31 0 -13 -86 -102 -201 -208 -163 -149 -206 -153 -342 -28 -95 87
-104 102 -82 135 27 42 58 32 143 -46 43 -38 86 -70 97 -70 12 0 76 55 160
135 150 144 177 158 209 113z"/>
</g>
</svg>

    );
}

export default booking;