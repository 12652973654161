import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import DateFormatter from "./Formatter/DateFormatter";
const Invoice = React.forwardRef(({ choiceOrder }, ref) => {
  return (
    <div ref={ref}>
      <h2>Invoice</h2>
      <p>Order ID: {choiceOrder.fullOrderId}</p>
      <p>Place: {choiceOrder.place_name}</p>
      <p>Quantity: {choiceOrder.quantity}</p>
      <p>Item: {choiceOrder.braid_name}</p>
      <p>Total Price: {choiceOrder.pricePromo ? choiceOrder.pricePromo : choiceOrder.price} $</p>
      <p>StartTime: {choiceOrder.startTime} </p>
      <p>EndTime: {choiceOrder.endTime} </p>
      <p>Date: {DateFormatter(choiceOrder.date)} </p>
      {/* Add other details needed for the invoice */}
    </div>
  );
});

export default Invoice;
