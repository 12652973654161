import React from 'react';
import Header from '../layout/header/header';
import Sidebar from '../layout/sidebar/sidebar';
import Center from '../layout/center/home';
import "../assets/styles/style.css";
function Home(props) {
    return (
        <div className='container'>
        <Header/>
        <Sidebar/>
        <Center/> 
      </div>
    );
}

export default Home;