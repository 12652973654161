import React from 'react';
import Header from '../layout/header/header';
import Sidebar from '../layout/sidebar/sidebar';
import BookCenter from '../layout/center/booking';
import "../assets/styles/style.css";
function Table(props) {
    return (
        <div className='container' >
        <Header/>
        <Sidebar/>
        <BookCenter/>
      </div>
    );
}

export default Table;