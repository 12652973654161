import React, { useState, useEffect, useRef } from "react";
import "../../assets/styles/style.css";
import personnel from "../../assets/icons/personnel.svg";
import money from "../../assets/icons/money.svg";
import braid from "../../assets/icons/braid.svg";
import like from "../../assets/icons/like.svg";
import chef from "../../assets/images/braider.svg";
import morIcon from "../../assets/icons/mor.svg";
import braid1 from "../../assets/icons/braid1.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../services/AuthServices";
import ConfirmDialog from "../../components/CustomDialog/ConfirmDialog";
import NumberFormatter from "../../components/Formatter/NumberFormatter";
import {
  FaEdit,
  FaTrash,
  FaPlus,
  FaChevronDown,
  FaChevronUp,
  FaEyeSlash,
  FaEye,
  FaUser,
  FaPhone,
  FaBriefcase,
  FaToggleOn,
  FaMoneyBillWave,
  FaMapMarkerAlt,
  FaEnvelope,
  FaClock,
} from "react-icons/fa";
import { colors } from "../../assets/config/color";

function Center(props) {
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const [showModalEditEmployer, setShowModalEditEmployer] = useState(false);
  const [userId, setUserId] = useState();
  const [token, setToken] = useState();
  const [employer, setEmployer] = useState([]);
  const toastId = useRef(null);
  const [showButton, setShowButton] = useState(true);
  const [selectedEmployer, setSelectedEmployer] = useState(null);
  const [roleName, setRoleName] = useState();
  const [roleDescription, setRoleDescription] = useState();
  const [roles, setRoles] = useState([]);
  const [editRole, setEditRole] = useState();
  const [selectedCategorie, setSelectedCategorie] = useState(null);
  const [confirmDeleteRole, setConfirmDeleteRole] = useState(false);
  const [selectedRole,setSelectedRole]=useState(null);
  const [confirmAddEmployer,setConfirmAddEmployer]=useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [startTime, setStartTime] = useState();
  const [firstName,setFirstName]=useState();
  const [lastName,setLastName]=useState();
  const [username,setUsername]=useState();
  const [email,setEnail]=useState();
  const [phone,setPhone]=useState();
  const [salaries,setSalaries]=useState();
  const [password,setPassword]=useState();
  const [poste,setPoste]=useState();
  const [address,setAddress]=useState();
  const [roleAdder,setRoleAdder]=useState();
  const [confirmDeleteEmployer, setConfirmDeleteEmployer] = useState(false);
  const [confirmEditEmployer,setConfirmEditEmployer]=useState(null);
  const [tronkedEmployer,setTronkedEmployer]=useState([]);
  const [totalPayment,setTotalPayment]=useState(0);
  const [totalBraid,setTotalBraids]=useState(0);
  const handleConfirmEditEmployer=(employer)=>{
    setShowButton(false);
    setConfirmEditEmployer(employer);
  }

  const handleConfirmDeleteEmployer =(selectedEmployer)=>{
    setConfirmDeleteEmployer({
      message:`Are you sure you want to delete this employer ('${selectedEmployer.firstName +' '+selectedEmployer.lastName}')?`,
      onConfirm:
      () => { handleDeleteEmployer(selectedEmployer.id);setConfirmDeleteEmployer(false); },
      onCancel: () => {handleCloseModal1(); },

    });
  }
  
  const customHandleChangle =(event,setEvent)=>{
    setEvent(event.target.value);
  }

 
const handleImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String= reader.result.replace('data:', '').replace(/^.+,/, '');
      setConfirmEditEmployer({...confirmEditEmployer,profil:base64String});
      setSelectedImage(base64String);
    };
    reader.readAsDataURL(file); 
  }
}
const handleOpenAddEmployer =(role)=>{
  setRoleAdder(role);
  setShowButton(false);
  setConfirmAddEmployer(true);
}

  const handleOpenDeleteRoleModal = (role) => {
    setConfirmDeleteRole(true);
    setSelectedRole(role);
  };
  const handleCancelEdit = () => {
    setEditRole(null);
    setShowButton(true);
  };
  const handleEditRole = (role) => {
    setEditRole({
      id: role.id,
      name: role.name,
      description: role.description,
      statut: role.statut,
    });
    setShowButton(false);
  };
  const handleRoleNameChange = (event) => {
    setRoleName(event.target.value);
  };
  const handleRoleDescriptionChange = (event) => {
    setRoleDescription(event.target.value);
  };
  const toggleEmployer = (index) => {
    setSelectedEmployer(selectedEmployer === index ? null : index);
  };
  const toggleEmployerCategorie = (index) => {
    setSelectedCategorie(selectedCategorie === index ? null : index);
  };
  const handleShowModal = () => {
    setShowModalEditEmployer(true);
  };
  const handleCloseModal = () => {
    setShowModalEditEmployer(false);
    setConfirmDeleteRole(null);
    setEditRole(null);
    setShowButton(true);
    setConfirmAddEmployer(null);
    setConfirmEditEmployer(null);

  };
  const handleCloseModal1 = () => {
    setConfirmDeleteRole(null);
    setEditRole(null);
    setShowButton(true);
    setConfirmAddEmployer(null);
    setConfirmDeleteEmployer(null);
    setConfirmEditEmployer(null);

  };
  const getUserData = () => {
    const tok = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    setToken(tok);
    setUserId(id);
  };
  const handleGetEmployer = async () => {
    try {
      if (token && userId) {
        const response = await AuthService.getAllUsers(token);
        if (response.data) {
          setEmployer(response.data);
          setTronkedEmployer(response.data.filter((item) => item.id !== +userId).slice( 0, 2));

        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(response.error);
          }
        }
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token ou User ID manquant");
        }
      }
    } catch (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Une erreur s'est produite lors de la soumission du formulaire"
        );
      }
    }
  };
  const [selected, setSelected] = useState(null);

  const addRole = () => {
    setShowButton(false);
    setSelected(1);
  };
  const cancelButton = () => {
    setShowButton(true);
    setSelected(null);
  };
  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    if (token) {
      handleGetEmployer();
      handleGetRoles();
      handleGetPayment();
    }
  }, [userId, token]);
  const handleAddRole = async () => {
    try {
      if (!roleName) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the name of the place, it's required!"
          );
        }
        return;
      }

      const response = await AuthService.addRole(
        userId,
        token,
        roleName,
        roleDescription
      );
      if (response.message) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(response.message);
        }
        selected(null);
        setShowButton(true);
        await handleGetRoles();
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(response.error);
        }
      }
    } catch (error) {
      console.log(error);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "An error occurred during form submission"
        );
      }
    }
  };
  const handleGetRoles = async () => {
    try {
      if (token && userId) {
        const response = await AuthService.getRoles(userId, token);
        if (response.data) {
          setRoles(response.data);
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(response.error);
          }
        }
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token ou User ID manquant");
        }
      }
    } catch (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Une erreur s'est produite lors de la soumission du formulaire"
        );
      }
    }
  };
  const handleUpdateRole = async() => {
    try{
      const response = await AuthService.updateRole(userId,token,editRole.id,editRole.name,editRole.description,editRole.statut)
      if (!editRole) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error('All are riquired');
        }
      }
  
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    await handleGetRoles();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error)
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
  };
  const handleDeleteRole = async(_id) => {
    try{
      const response = await AuthService.deleteRole(token,_id);
 
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    await handleGetRoles();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error);
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
    handleCloseModal1();
  };
  const handleAddEmployer = async () => {
    try {
      if (!firstName || firstName==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the firstName of the employee, it's required!"
          );
        }
        return;
      }
      if (!lastName || lastName==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the lastName of the employee, it's required!"
          );
        }
        return;
      }
      if (!username || username==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the username of the employee, it's required!"
          );
        }
        return;
      }
      if (!email || email==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the email of the employer, it's required!"
          );
        }
        return;
      }
      if (!password || password==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the password of the employee, it's required!"
          );
        }
        return;
      }
      if (!roleAdder) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the role for this employee, it's required!"
          );
        }
        return;
      }

      const response = await AuthService.addEmployer(
        username,
        password,
        email,
        roleAdder.id,
        firstName,
        lastName,
        phone,
        selectedImage,
        salaries,
        startTime,
        poste,
        address

      );
      if (response.message) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(response.message);
        }
        setConfirmAddEmployer(null);
        setShowButton(true);
        await handleGetRoles();
        await handleGetEmployer();
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(response.error);
        }
      }
    } catch (error) {
      console.log(error);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "An error occurred during form submission"
        );
      }
    }
  };
  const handleDeleteEmployer = async(_id) => {
    try{
      const response = await AuthService.deleteEmployer(token,_id);
 
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    await handleGetRoles();
    await handleGetEmployer();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error);
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
    handleCloseModal1();
  };
  const handleUpdateEmployer = async() => {
    try{
      const response = await AuthService.updateEmployer(confirmEditEmployer.id,token,confirmEditEmployer.username,confirmEditEmployer.password,confirmEditEmployer.email,confirmEditEmployer.roleId,confirmEditEmployer.firstName,confirmEditEmployer.lastName,confirmEditEmployer.roleId,confirmEditEmployer.profil,confirmEditEmployer.salaries,confirmEditEmployer.workHour,confirmEditEmployer.poste,confirmEditEmployer.address)
      if (!confirmEditEmployer.firstName || confirmEditEmployer.firstName==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the firstName of the employee, it's required!"
          );
        }
        return;
      }
      if (!confirmEditEmployer.lastName || confirmEditEmployer.lastName==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the lastName of the employee, it's required!"
          );
        }
        return;
      }
      if (!confirmEditEmployer.username || confirmEditEmployer.username==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the username of the employee, it's required!"
          );
        }
        return;
      }
      if (!confirmEditEmployer.email || confirmEditEmployer.email==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the email of the employer, it's required!"
          );
        }
        return;
      }
      if (!confirmEditEmployer.password || confirmEditEmployer.password==='') {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the password of the employee, it's required!"
          );
        }
        return;
      }
  
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    setConfirmEditEmployer(null);
    setShowButton(true);
    await handleGetRoles();
    await handleGetEmployer();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error)
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
  };
  const [showAllEmployer,setShowAllEmployer]=useState(false);
 const handleViewAllEmployer =()=>{
  setShowAllEmployer(true);
  setTronkedEmployer(employer.filter((item) => item.id !== +userId));
 }
 const handleViewLessEmployer =()=>{
  setShowAllEmployer(false);
  setTronkedEmployer(employer.filter((item) => item.id !== +userId).slice(0, 2));
 }
 const handleGetPayment = async () => {
  try {
    if (token) {
      const response = await AuthService.getStatistique( token);
      if (response) {
        setTotalPayment(response.sum);
        setTotalBraids(response.braid);
      }
    } else {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Token ou User ID manquant");
      }
    }
  } catch (error) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(
        "An error occurred while submitting the form"
      );
    }
  }
};
  return (
    <div className={`${isSmallScreen ? "main-contentHR" : "main-contentH"}`}>
      <div className={`${isSmallScreen ? "column" : "row"}`}>
        {showModalEditEmployer && (
          <div className="modal-overlay">
            <div className="modal">
              <div className="row">
                <div className="form-group">
                  <label>Choice an option</label>
                </div>
                <div className="form-group">
                  <button className="close-button" onClick={handleCloseModal}>
                    X
                  </button>
                </div>
              </div>

              {showButton ? (
  <>
    <div className="row">
      <div>
        <button className="button" onClick={() => addRole()}>
          <FaPlus size={15} /> ADD ROLE
        </button>
      </div>
    </div>
    <div className="category-list">
      {roles && roles.length > 0 ? (
        roles.map((role, index) => {
          const roleEmployers = employer.filter(
            (empl) => empl.roleId === role.id
          );

          return (
            <div key={index} className="category-item">
              <div className="category-header">
                <span>{role.name}</span>
                <div className="crud-icons">
                  <FaEdit
                    title="Edit"
                    className="icon-edit"
                    onClick={() => handleEditRole(role)}
                  />
                  {role.statut ? (
                    <FaEye
                      title="Desabled"
                      size={20}
                      className="icon-eye"
                    />
                  ) : (
                    <FaEyeSlash
                      title="Enabled"
                      size={20}
                      className="icon-eyedrop"
                    />
                  )}
                  <FaTrash
                    title="Delete"
                    className="icon-delete"
                    onClick={() => handleOpenDeleteRoleModal(role)}
                  />
                  <FaPlus
                    title="Add Employer"
                    className="icon-add"
                    onClick={() => handleOpenAddEmployer(role)}
                  />
                  {roleEmployers.length > 0 && (
                    <div
                      className="toggle-button"
                      onClick={() => toggleEmployerCategorie(index)}
                    >
                      {selectedCategorie === index ? (
                        <FaChevronUp color="#a9a9a9" />
                      ) : (
                        <FaChevronDown color="#a9a9a9" />
                      )}
                    </div>
                  )}
                </div>
              </div>

              {selectedCategorie === index && (
                <div className="category-types">
                  {roleEmployers.length > 0 ? (
                    roleEmployers.map((user, empIndex) => (
                      <div key={empIndex} className="type-itemG">
                        <div className="type-item1">
                          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                          {
                            (user.profil && user.profil !== null)? (
                            <img src={`data:image;base64,${user.profil}`} alt="profil" class="employees-list-item-img1"/>
                            ):<FaUser size={25}/>
                          }
                          <span style={{marginLeft:'8px'}}>
                            {user.firstName + " " + user.lastName}
                          </span>
                          </div>
                          
                          <div className="type-actions">
                            <FaEdit title="Edit" className="icon-edit"
                            onClick={() => handleConfirmEditEmployer(user)}
                            />
                            {user.isActive ? (
                              <FaEye
                                title="Disable"
                                size={20}
                                className="icon-small icon-eye"
                              />
                            ) : (
                              <FaEyeSlash
                                title="Enable"
                                size={20}
                                className="icon-eyedrop"
                              />
                            )}
                            <FaTrash
                              title="Delete"
                              className="icon-small icon-delete"
                              onClick={()=>handleConfirmDeleteEmployer(user)}
                            />
                            <div
                              className="toggle-button"
                              onClick={() => toggleEmployer(empIndex)}
                            >
                              {selectedEmployer === empIndex ? (
                                <FaChevronUp color="#a9a9a9" />
                              ) : (
                                <FaChevronDown color="#a9a9a9" />
                              )}
                            </div>
                          </div>
                        </div>

                        {selectedEmployer === empIndex && (
                          <div className="employer-details">
                            <div className="detail-item">
                              <FaUser className="icon" />
                              <span className="label">Username:</span>
                              <span className="value">{user.username}</span>
                            </div>

                            <div className="detail-item">
                              <FaEnvelope className="icon" />
                              <span className="label">Email:</span>
                              <span className="value">{user.email}</span>
                            </div>

                            <div className="detail-item">
                              <FaMapMarkerAlt className="icon" />
                              <span className="label">Address:</span>
                              <span className="value">{user.address}</span>
                            </div>

                            <div className="detail-item">
                              <FaPhone className="icon" />
                              <span className="label">Phone:</span>
                              <span className="value">{user.phoneNumber}</span>
                            </div>

                            <div className="detail-item">
                              <FaMoneyBillWave className="icon" />
                              <span className="label">Salaries:</span>
                              <span className="value">{user.salaries}</span>
                            </div>

                            <div className="detail-item">
                              <FaClock className="icon" />
                              <span className="label">Hours per Week:</span>
                              <span className="value">{user.workHour}H/week</span>
                            </div>

                            <div className="detail-item">
                              <FaBriefcase className="icon" />
                              <span className="label">Job:</span>
                              <span className="value">{user.poste}/Employer</span>
                            </div>

                            <div className="detail-item">
                              <FaToggleOn className="icon" />
                              <span className="label">Active:</span>
                              <span className="value">{user.isActive ? "Yes" : "No"}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div>No employers assigned to this role</div>
                  )}
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div>No roles yet</div>
      )}
    </div>
  </>
) : null}

              {selected === 1 && (
                <div
                  className="column"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <div className="form-group">
                    <label style={{ color: colors.gold }}>
                      Add Role option
                    </label>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <label for="name" style={{ marginBottom: "16px" }}>
                        NAME<span style={{ color: "red" }}>*</span>
                      </label>

                      <input
                        value={roleName}
                        type="text"
                        id="name"
                        placeholder="categorie name"
                        onChange={(event) => handleRoleNameChange(event)}
                        required
                      />
                    </div>
                    <div className="form-group" style={{ marginLeft: "12px" }}>
                      <label for="name" style={{ marginBottom: "16px" }}>
                        DESCRIPTION:
                      </label>
                      <input
                        value={roleDescription}
                        type="text"
                        id="name"
                        placeholder="categorie description"
                        onChange={(event) => handleRoleDescriptionChange(event)}
                      />
                    </div>
                  </div>
                  <div className="rowL">
                    <div className="button-container">
                      <button
                        className="button"
                        onClick={() => handleAddRole()}
                      >
                        ADD
                      </button>
                    </div>
                    <div className="button-container">
                      <button
                        className="button"
                        style={{
                          marginLeft: "12px",
                          backgroundColor: colors.withGold,
                        }}
                        onClick={cancelButton}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {editRole && (
              <div
                className="column"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div className="form-group">
                  <label style={{ color: colors.gold }}>
                    Edit Role "({editRole.name})"
                  </label>
                </div>
                <div className="row">
                  <div className="form-group">
                    <label for="name" style={{ marginBottom: "16px" }}>
                      NAME<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      value={editRole.name}
                      type="text"
                      id="name"
                      placeholder="categorie name"
                      onChange={(event) =>
                        setEditRole({ ...editRole, name: event.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginLeft: "12px" }}>
                    <label for="name" style={{ marginBottom: "16px" }}>
                      DESCRIPTION
                    </label>
                    <input
                      value={editRole.description || ""}
                      type="text"
                      id="name"
                      placeholder="categorie description"
                      onChange={(event) =>
                        setEditRole({
                          ...editRole,
                          description: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row form-group">
                      <label>STATUS:</label>
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="radio"
                            name="status"
                            value="true"
                            checked={editRole.statut === true}
                            onChange={(e) => setEditRole({
                              ...editRole,
                              statut: true,
                            })}
                           
                          />
                          Enabled
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="status"
                            value="false"
                            checked={editRole.statut === false}
                            onChange={(e) => setEditRole({
                              ...editRole,
                              statut:false,
                            })}
                          />
                          Disabled
                        </label>
                       
                      </div>
                    </div>
                <div className="rowL">
                  <div className="button-container" onClick={()=>handleUpdateRole()}>
                    <button className="button">UPDATE</button>
                  </div>
                  <div className="button-container">
                    <button
                      className="button"
                      style={{
                        marginLeft: "12px",
                        backgroundColor: colors.withGold,
                      }}
                      onClick={handleCancelEdit}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </div>
            )}
             {roleAdder && confirmAddEmployer &&(
                <div className="column">
                <div className="rowL" style={{marginBottom:'16px'}} >
                    <label style={{color:colors.gold}}>Add Employer to ({roleAdder.name})</label>
                </div>
                <div className="row" style={{width:'100%'}}>
                <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>FirstName<span style={{color:'red'}}>*</span></label>
                <input value={firstName} type="text" id="name" placeholder="firstName" onChange={(e)=>customHandleChangle(e,setFirstName)} required  />
              </div>
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>LastName<span style={{color:'red'}}>*</span></label>
                <input value={lastName}  type="text" id="name" placeholder="lastName" onChange={(e)=>customHandleChangle(e,setLastName)}   />
              </div>
              </div>
              <div className="row" style={{width:'100%'}}>
                <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Username<span style={{color:'red'}}>*</span></label>
                <input  value={username} type="text" id="name" placeholder="username" onChange={(e)=>customHandleChangle(e,setUsername)}  />
              </div>
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Email<span style={{color:'red'}}>*</span></label>
                <input value={email }  type='email' id="name" placeholder="exemple@gmail.com" onChange={(e)=>customHandleChangle(e,setEnail)} />
              </div>
              </div>
              <div className="row" style={{width:'100%'}}>
                <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Phone</label>
                <input value={phone}  type='number' id="name" placeholder="0876543212" onChange={(e)=>customHandleChangle(e,setPhone)}  />
              </div>
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Address</label>
                <input value={address} type='email' id="name" placeholder="New York" onChange={(e)=>customHandleChangle(e,setAddress)}  />
              </div>
              </div>
                <div className="row" style={{width:'100%'}}>
                <div className="form-group" style={{width:'45%'}} >
                <label for="name" style={{marginBottom:'16px'}}>Role<span style={{color:'red'}}>*</span></label>
                <input type='text' readOnly value={roleAdder.name} />
                  </div>
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Job</label>
                <input value={poste} type="text" id="name" placeholder="poste name" onChange={(e)=>customHandleChangle(e,setPoste)}  />
              </div>
              </div>
              <div className="row">
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Hours per Week.</label>
                <input value={startTime}  type='number' id="name" placeholder="35h/week" onChange={(e)=>customHandleChangle(e,setStartTime)}  />
              </div>
              <div className="form-group" style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Salaries</label>
                <input value={salaries} min={1}  type="number" id="name" placeholder="240000$" onChange={(e)=>customHandleChangle(e,setSalaries)}  />
              </div>
             
              </div>
             
      <div className="row">
      <div className="form-group"style={{width:'45%'}}>
      <label htmlFor="imageUpload" style={{marginBottom:'16px'}}>Profil
      <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" onChange={(event) => handleImageUpload(event)} /></label>
    
      </div>
        <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Password<span style={{color:'red'}}>*</span></label>
                <input value={password} type='password' id="name" placeholder="poste name" onChange={(e)=>customHandleChangle(e,setPassword)}   />
              </div>
      </div>
              <div className="rowL">
              <div className="button-container"><button className="button" onClick={()=>handleAddEmployer()} >ADD</button></div>
              <div className="button-container"><button className="button" style={{marginLeft:'12px',backgroundColor:colors.withGold}} onClick={handleCloseModal1}>CANCEL</button></div>
              </div>
 
              </div>
              )
            }
            {confirmEditEmployer &&(
                <div className="column">
                <div className="rowL" style={{marginBottom:'16px'}} >
                    <label style={{color:colors.gold}}>Edit Employer ({confirmEditEmployer.firstName+' '+confirmEditEmployer.lastName})</label>
                </div>
                <div className="row" style={{width:'100%'}}>
                <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>FirstName<span style={{color:'red'}}>*</span></label>
                <input value={confirmEditEmployer.firstName} type="text" id="name" placeholder="firstName" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,firstName:e.target.value})} required  />
              </div>
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>LastName<span style={{color:'red'}}>*</span></label>
                <input value={confirmEditEmployer.lastName}  type="text" id="name" placeholder="lastName" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,lastName:e.target.value})}   />
              </div>
              </div>
              <div className="row" style={{width:'100%'}}>
                <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Username<span style={{color:'red'}}>*</span></label>
                <input  value={confirmEditEmployer.username} type="text" id="name" placeholder="username" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,username:e.target.value})}  />
              </div>
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Email<span style={{color:'red'}}>*</span></label>
                <input value={confirmEditEmployer.email }  type='email' id="name" placeholder="exemple@gmail.com" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,email:e.target.value})} />
              </div>
              </div>
              <div className="row" style={{width:'100%'}}>
                <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Phone</label>
                <input value={confirmEditEmployer.phoneNumber}  type='number' id="name" placeholder="0876543212" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,phoneNumber:e.target.value})}  />
              </div>
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Address</label>
                <input value={confirmEditEmployer.address} type='email' id="name" placeholder="New York" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,address:e.target.value})}  />
              </div>
              </div>
                <div className="row" style={{width:'100%'}}>
                <div className="form-group" style={{width:'45%'}} >
                <label for="name" style={{marginBottom:'16px'}}>Role<span style={{color:'red'}}>*</span></label>
               <select value={confirmEditEmployer.roleId} onChange={(event)=>setConfirmEditEmployer({...confirmEditEmployer,roleId:+(event.target.value)})}>
                   {
                    roles.map((role,index)=>(
                        <option
                        key={index}
                        value={role.id}
                        selected={confirmEditEmployer.roleId === role.id}
                        >
                          {role.name}
                        </option>
                      ))
                   }
               </select>
                  </div>
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Job</label>
                <input value={confirmEditEmployer.poste} type="text" id="name" placeholder="poste name" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,poste:e.target.value})}  />
              </div>
              </div>
              <div className="row">
              <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Hours per Week.</label>
                <input value={confirmEditEmployer.workHour}  type='number' id="name" placeholder="35h/week" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,workHour:e.target.value})}  />
              </div>
              <div className="form-group" style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>Salaries</label>
                <input value={confirmEditEmployer.salaries} min={1}  type="number" id="name" placeholder="240000$" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,salaries:e.target.value})}  />
              </div>
             
              </div>
             
      <div className="row">
      <div className="form-group"style={{width:'45%'}}>
      <label htmlFor="imageUpload" style={{marginBottom:'16px'}}>Profil
      <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" onChange={(event) => handleImageUpload(event)} /></label>
      </div>
        <div className="form-group"style={{width:'45%'}}>
                <label for="name" style={{marginBottom:'16px'}}>newPassword<span style={{color:'red'}}>*</span></label>
                <input type='password' id="name" placeholder="******" onChange={(e)=>setConfirmEditEmployer({...confirmEditEmployer,password:e.target.value})}   />
        </div>
      </div>
              <div className="rowL">
              <div className="button-container"><button className="button" onClick={()=>handleUpdateEmployer()} >UPDATE</button></div>
              <div className="button-container"><button className="button" style={{marginLeft:'12px',backgroundColor:colors.withGold}} onClick={handleCloseModal1}>CANCEL</button></div>
              </div>
 
              </div>
              )
            }
            </div>

          </div>
        )}
        {confirmDeleteRole && (
          <ConfirmDialog
            message={`Are you sure you want to delete (${selectedRole.name})?`}
            onConfirm={()=>handleDeleteRole(selectedRole.id)}
            onCancel={handleCloseModal1}
            texConfirm={"YES"}
            textCancel={"NO"}
          />
        )}
        
        {confirmDeleteEmployer && (
          <ConfirmDialog
            message={confirmDeleteEmployer.message}
            onConfirm={confirmDeleteEmployer.onConfirm}
            onCancel={confirmDeleteEmployer.onCancel}
            texConfirm={"YES"}
            textCancel={"NO"}
          />
        )}
        <div className="main-contentH1">
          <div className="padding">
            <div className="row">
              <div
                className="card "
                style={{
                  width: isSmallScreen ? "110px" : "140px",
                  height: isSmallScreen ? "160px" : "200px",
                }}
              >
                <div className="card-icon card-icon-orange">
                  <img src={personnel} alt="" />
                </div>
                <div className="card-title ">Staff</div>
                <div className="card-value card-title-orange">
                  {
                    employer.length
                  }
                </div>
              </div>
              <div
                className="card"
                style={{
                  width: isSmallScreen ? "110px" : "140px",
                  height: isSmallScreen ? "160px" : "200px",
                }}
              >
                <div className="card-icon card-icon-green">
                  <img src={braid} alt="" />
                </div>
                <div className="card-title ">Braid</div>
                <div className="card-value card-title-green">{totalBraid}</div>
              </div>
              <div
                className="card"
                style={{
                  width: isSmallScreen ? "110px" : "140px",
                  height: isSmallScreen ? "160px" : "200px",
                }}
              >
                <div className="card-icon card-icon-red">
                  <img src={money} alt="" />
                </div>
                <div className="card-title ">Total Sales</div>
                <div className="card-value card-title-red">{NumberFormatter(totalPayment)}</div>
              </div>
            </div>
            <div class="articles-section">
              <div class="articles-card-title">Most Purchased Items</div>
              <div class="row-card">
                <div class="articles-card">
                  <div class="articles-card-img">
                    <img src={braid1} alt="" />
                  </div>
                  <div class="articles-card-info">
                    <div className="titleColored">
                      <span style={{ color: "#000000" }}>
                        HAIR <span>BOB</span>
                      </span>
                    </div>
                    <div className="titleColored1">
                      <span>
                        101564{" "}
                        <span style={{ color: "#000000" }}> purchases</span>{" "}
                      </span>
                    </div>
                    <div className="titleColored1">
                      <div className="row-card1">
                        <img src={like} alt="" />
                        <span>54 K</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="articles-card">
                  <div class="articles-card-img">
                    <img src={braid1} alt="" />
                  </div>
                  <div class="articles-card-info">
                    <div className="titleColored">
                      <span style={{ color: "#000000" }}>
                        HAIR <span>BOX BRAIDS</span>
                      </span>
                    </div>
                    <div className="titleColored1">
                      <span>
                        101564{" "}
                        <span style={{ color: "#000000" }}> purchases</span>{" "}
                      </span>
                    </div>
                    <div className="titleColored1">
                      <div className="row-card1">
                        <img src={like} alt="" />
                        <span>54 K</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="articles-card">
                  <div class="articles-card-img">
                    <img src={braid1} alt="" />
                  </div>
                  <div class="articles-card-info">
                    <div className="titleColored">
                      <span style={{ color: "#000000" }}>
                        HAIR <span>MICRO-BRAIDS</span>
                      </span>
                    </div>
                    <div className="titleColored1">
                      <span>
                        101564{" "}
                        <span style={{ color: "#000000" }}> purchases</span>{" "}
                      </span>
                    </div>
                    <div className="titleColored1">
                      <div className="row-card1">
                        <img src={like} alt="" />
                        <span>54 K</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="employees-section">
              <div class="employees-title-section">
                <div className="employees-title-section-name">
                  <span> Employees</span>
                </div>
                <div className="employees-title-section-btn" onClick={()=>{
                  showAllEmployer? handleViewLessEmployer():handleViewAllEmployer()
                  }}>
                  {
                    showAllEmployer ? <span>View Less</span> : <span> View All </span>
                  }
                </div>
              </div>
              <div className="divider"></div>
              <div class="employees-section-content">
              {
              tronkedEmployer.length>0?
              tronkedEmployer.map((emp,index)=>(
                <>
                <li class="employees-list-item" key={index}>
                {(emp.profil && emp.profil !== null)? (
                            <img src={`data:image;base64,${emp.profil}`} alt="profil" className="employees-list-item-img"/>
                            ):
                            <FaUser className="employees-list-item-img"/>
                          }
                  <div class="employees-list-item-info">
                    <div>
                      <div class="employees-list-item-name">{emp.firstName+' '+emp.lastName}</div>
                      <div class="employees-list-item-role">{emp.poste || 'Employer'}</div>
                    </div>
                    <div class="employees-list-item-hours"> {emp.workHour+' '*'hours/week' || '35 hours/week'}</div>
                  </div>
                </li>
                <div className="divider"></div>
                </>
              )):<div>No Employer yet</div>
            }
              </div>
            </div>
          </div>
        </div>

        <div className="right-sidebarH ">
          <div className="padding">
            <div class="gestion-employee-section">
              <div class="img-section">
                <img src={chef} alt="" />
              </div>
              <div class="bg-section" />
              <div className="bg-content" onClick={() => handleShowModal()}>
                <div className="arrow-container">
                  <div>
                    <div className="subheading">
                      <span>Manage Your Staff</span>
                    </div>
                    <div className="subheading1">
                      <span>Add, Edit, Manage their Salaries</span>
                    </div>
                  </div>
                  <div className="container-back"> →</div>
                </div>
              </div>
            </div>

            <div className="cmd-content">
              <div class="employees-title-section">
                <div className="employees-title-section-name">
                  <span> Recent Orders</span>
                </div>
                <div className="employees-title-section-btn">
                  <span> View All</span>
                </div>
              </div>
              <div className="order-card">
                <div className="header">Order #D87415DA</div>
                <div className="details">
                  <span>Place 1 - 6 Hair(s)</span>
                  <span>Total Purchased: 9000 $</span>
                  <span>Discount: Yes</span>
                  <div className="actions">
                    <img src={morIcon} alt="" />
                  </div>
                </div>
              </div>

              <div className="order-card">
                <div className="header">Order #D87415DA</div>
                <div className="details">
                  <span>Place 1 - 6 Hair(s)</span>
                  <span>Total Purchased: 9000 $</span>
                  <span>Discount: Yes</span>
                  <div className="actions">
                    <img src={morIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} position="top-center" />
    </div>
  );
}

export default Center;
