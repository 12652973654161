import React, { useState, useEffect } from "react";
import LoginBg from "../assets/images/braid-african.jpg";
import LoginImg from "../assets/images/img-login.svg";
import LogoLogin from "../assets/logos/log.jpg";
import "../assets/styles/style.css";
import Btn from "../components/button/btnWithoutIcon";
import Input from "../components/input/input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "../services/AuthServices";
import { useNavigate } from "react-router-dom";
import SkeletonLoader from "../components/preloader/SkeletonLoader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { colors } from "../assets/config/color";
import "../assets/styles/style.css";
const styles = {
  container: {
    backgroundImage: `url(${LoginBg})`,
    backgroundSize: "cover", 
    backgroundPosition: "center", 
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    overflow: "hidden",
    margin: "0",
  },
  colorOrange: {
    color: colors.gold,
  },
};

function Login(props) {
  const matches = useMediaQuery("(min-width:700px)");
  const token = localStorage.getItem("token");
  console.log(token);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [loading, setLoading] = useState(true);
  const toastId = React.useRef(null);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleLogin = async () => {
    setIsloading(true);
    try {
      if (!username || !password) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Nom d'utilisateur et mot de passe sont requis."
          );
        }
        return;
      }
      const Regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!username.match(Regex)) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Veuillez renseigner une adresse e-mail valide !"
          );
        }
        return;
      }
      const response = await AuthService.login(username, password);
      if (response.token) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("email", response.data["email"]);
        localStorage.setItem("id", response.data["id"]);
        localStorage.setItem("firstName", response.data["firstName"]);
        localStorage.setItem("lastName", response.data["lastName"]);
        localStorage.setItem("Role", response.data["role"]);
        window.location.replace("/home");
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(response.error);
        }
      }
    } catch (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Une erreur s'est produite lors de la soumission du formulaire"
        );
      }
    } finally {
      setIsloading(false);
    }
  };

  const usernamechange = (event) => {
    setUsername(event.target.value);
  };

  const passwordchange = (event) => {
    setPassword(event.target.value);
  };
  if (loading) {
    return <SkeletonLoader />;
  }
  return (
    <div style={styles.container}>
      <div
        className="loginItem"
        style={{ flexDirection: matches ? "row" : "column" }}
      >
        <div className="columnL">
          <img src={LogoLogin} alt="" width={200} height="120px" />
          <span className="txtL">
            <span style={styles.colorOrange}>Transform</span> your look,
            <span style={styles.colorOrange}> embrace</span> your style, and
            <span style={styles.colorOrange}> enjoy</span> beautiful braids with
            the ultimate <span style={styles.colorOrange}>confidence</span> at
            our braiding salon.
          </span>

          <img src={LoginImg} alt="" width={"100%"} />
        </div>
        <div className="columnL1">
          <div className="columnContent">
            <h3
              style={{
                color: colors.gold,
                marginBottom: "20px",
                fontSize: "30px",
              }}
            >
              Login
            </h3>
            <form>
              <div style={{ marginBottom: "12px", width: "100%" }}>
                <label className="label">Username</label>
              </div>
              <Input
                placeholder={"adresse@mail.com"}
                onChange={(event) => usernamechange(event)}
              />
              <br />
              <div style={{ marginBottom: "12px" }}>
                <label className="label">Password</label>
              </div>

              <Input
                placeholder={"Password"}
                onChange={(event) => passwordchange(event)}
                type="password"
              />
              <br />
              <div
                className="rowEnd"
                onClick={() => handleNavigation("resetpassword")}
              >
                <span style={{ textDecoration: "underline" }}>
                  Forgot password ?
                </span>
              </div>
              <br />
              <Btn
                txtbtn={isloading ? "Loading..." : "Se Connecter"}
                bgcolor={"168, 102, 127"}
                bgopacity={isloading ? 0.5 : 1}
                txtcolor={"#fff"}
                action={
                   () => handleLogin()
                }
              />
            </form>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} position="top-center" />
    </div>
  );
}

export default Login;
