import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './input.css';

function Input({ placeholder, onChange, value, type = 'text' }) {
    const [inputType, setInputType] = useState(type);

    const togglePasswordVisibility = () => {
        setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
    };

    return (
        <div className="input-container">
            <input
                type={inputType}
                placeholder={placeholder}
                required
                onChange={onChange}
                value={value}
            />
            {type === 'password' && (
                <button
                    type="button"
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                >
                    {inputType === 'password' ? <FaEye color='#a9a9a9' /> : <FaEyeSlash color='#a9a9a9' />}
                </button>
            )}
        </div>
    );
}

export default Input;
