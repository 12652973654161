import {jwtDecode} from 'jwt-decode';
const BASE_URL = "https://ginasafricanhairbraiding.terangapp.com/api";

const AuthService = {
  login: async (username, password) => {
   return fetch(`${BASE_URL}/users/login`, {
      method: "POST", headers: { "Content-Type": "application/json", },
      body: JSON.stringify({ username, password }),
    }).then((data) => data.json());
  },
  logout: () => {localStorage.removeItem('token'); },
  isLoggedIn: () => {
    const token = AuthService.getToken();
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    }
    return false;
  },
  getToken: () => {return localStorage.getItem('token'); },

  // gestion des categories ou style
  getBraidsCategories: async (userId, token) => {
    return fetch(`${BASE_URL}/braids/getCategories/${userId}`, {
      method: "GET", headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    }).then((data) => data.json());
  },
 addNewCategories:async (userId,token,name,description)=>{
  const data = await fetch(`${BASE_URL}/braids/createCategorie/${userId}`, {
     method: "POST", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body:JSON.stringify({
      name,description
     })
   });
   return await data.json();
 },
 deleteCategories:async (userId,token,_id)=>{
  const data = await fetch(`${BASE_URL}/braids/deleteCategorie/${userId}`, {
     method: "DELETE", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body:JSON.stringify({
      _id
     })
   });
   return await data.json();
 },
 updateCategories:async (userId,token,_id,name,description)=>{
  const data = await fetch(`${BASE_URL}/braids/updateCategorie/${userId}`, {
     method: "PUT", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body:JSON.stringify({
      _id,
      name,description
     })
   });
   return await data.json();
 },
//  gestion des types ou length
 addNewType:async (userId,token,name,description,type_categories_id)=>{
  const data = await fetch(`${BASE_URL}/braids/createType/${userId}`, {
     method: "POST", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body:JSON.stringify({
      name,description,type_categories_id
     })
   });
   return await data.json();
 },
 updateType:async (userId,token,typeId,name,description,type_categories_id)=>{
  const data = await fetch(`${BASE_URL}/braids/updateType/${userId}`, {
     method: "PUT", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body:JSON.stringify({
      typeId, name,description,type_categories_id
     })
   });
   return await data.json();
 },
 deleteType:async (userId,token,typeId)=>{
  const data = await fetch(`${BASE_URL}/braids/deleteType/${userId}`, {
     method: "DELETE", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body:JSON.stringify({
      typeId
     })
   });
   return await data.json();
 },
 getAllTypes:async (userId,token)=>{
  const data = await fetch(`${BASE_URL}/braids/getTypes/${userId}`, {
     method: "GET", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
   });
   return await data.json();
 },
// gestion braid
 addBraid:async (userId,token,braid_type_id,employer_id,description,priceNormal,pricePromo,isPromo,statut,image,time)=>{
  const data = await fetch(`${BASE_URL}/braids/createBraid/${userId}`, {
     method: "POST", headers: {
      "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body: JSON.stringify({braid_type_id,employer_id,description,priceNormal,pricePromo,isPromo,statut,image,time})
   });
   return await data.json();
 },
 getAllBraids:async (userId,token)=>{
  const data = await fetch(`${BASE_URL}/braids/getBraids/${userId}`, {
     method: "GET", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
   });
   return await data.json();
 },
 updateBraid:async (userId,token,id,braid_type_id,employer_id,description,priceNormal,pricePromo,isPromo,statut,image,time)=>{
  const data = await fetch(`${BASE_URL}/braids/updateBraid/${userId}`, {
     method: "PUT", headers: {
      "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body: JSON.stringify({id,braid_type_id,employer_id,description,priceNormal,pricePromo,isPromo,statut,image,time})
   });
   return await data.json();
 },
 deleteBraid:async (userId,token,braidId)=>{
  const data = await fetch(`${BASE_URL}/braids/deleteBraid/${userId}/${braidId}`, {
     method: "DELETE", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     }
   });
   return await data.json();
 },

 // gestion employers
 getEmployers:async (token)=>{
  const data = await fetch(`${BASE_URL}/users/getAllEmployer`, {
     method: "GET", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
   });
   return await data.json();
 },
// gestion des places
 addPlace:async (userId,token,name, capacity, description, isBookable)=>{
  const data = await fetch(`${BASE_URL}/place/createPlace/${userId}`, {
     method: "POST", headers: {
      "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body: JSON.stringify({name, capacity, description, isBookable})
   });
   return await data.json();
 },

 getPlaces:async (token)=>{
  const data = await fetch(`${BASE_URL}/place/getAllPlace`, {
     method: "GET", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
   });
   return await data.json();
 },
 deletePlace:async (userId,token,placeId)=>{
  const data = await fetch(`${BASE_URL}/place/deletePlace/${userId}/${placeId}`, {
     method: "DELETE", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     }
   });
   return await data.json();
 },
 updatePlace:async (userId,token,id,name, status, capacity, description, isBookable )=>{
  const data = await fetch(`${BASE_URL}/place/updatePlace/${userId}/${id}`, {
     method: "PUT", headers: {
      "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body: JSON.stringify({name, status, capacity, description, isBookable })
   });
   return await data.json();
 },

//  gestions des commandes
getOrders:async (token)=>{
  const data = await fetch(`${BASE_URL}/order/getAllOrder`, {
     method: "GET", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
   });
   return await data.json();
 },
deleteOrder:async (token,id)=>{
  const data = await fetch(`${BASE_URL}/order/deleteOrder/${id}`, {
     method: "DELETE", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     }
   });
   return await data.json();
 },
 updateOrder:async (token,id,place_id,status)=>{
  const data = await fetch(`${BASE_URL}/order/releasePlace/${id}`, {
    method: "PUT", headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({place_id,status})
      });
      return await data.json();
      },
completedOrder:async (token,id)=>{
  const data = await fetch(`${BASE_URL}/order/completedOrder/${id}`, {
    method: "PUT", headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`
      },});
      return await data.json();
      },
getStatistique:async (token)=>{
        const data = await fetch(`${BASE_URL}/order/statistique`, {
           method: "GET", headers: {
             "Content-Type": "application/json",
             'Authorization': `Bearer ${token}`
           },
         });
         return await data.json();
       },
// gestion des employers
 getAllEmployer:async (token)=>{
  const data = await fetch(`${BASE_URL}/users/getAllEmployer`, {
     method: "GET", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
   });
   return await data.json();
 },
 addEmployer:async (username,password,email,role,firstName,lastName,phone,profil,salaries,workHour,poste,address)=>{
  const data = await fetch(`${BASE_URL}/users/register`, {
     method: "POST", headers: {
      "Content-Type": "application/json"
     },
     body: JSON.stringify({username,password,email,role,firstName,lastName,phone,profil,salaries,workHour,poste,address})
   });
   return await data.json();
 },
 deleteEmployer:async (token,id)=>{
  const data = await fetch(`${BASE_URL}/users/deleteUser/${id}`, {
     method: "DELETE", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     }
   });
   return await data.json();
 },
 updateEmployer:async (id,token,username,password,email,role,firstName,lastName,phone,profil,salaries,workHour,poste,address )=>{
  const data = await fetch(`${BASE_URL}/users/updateUser/${id}`, {
     method: "PUT", headers: {
      "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body: JSON.stringify({username,password,email,role,firstName,lastName,phone,profil,salaries,workHour,poste,address })
   });
   return await data.json();
 },
//  get all users
getAllUsers:async (token)=>{
  const data = await fetch(`${BASE_URL}/users/getAllUsers`, {
     method: "GET", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
   });
   return await data.json();
 },
//  gestion des roles
 addRole:async (userId,token,name, description)=>{
  const data = await fetch(`${BASE_URL}/users/roles/${userId}`, {
     method: "POST", headers: {
      "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body: JSON.stringify({name, description})
   });
   return await data.json();
 },
 getRoles:async (userId,token)=>{
  const data = await fetch(`${BASE_URL}/users/roles/${userId}`, {
     method: "GET", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
   });
   return await data.json();
 },
 updateRole:async (userId,token,id,name, description, statut )=>{
  const data = await fetch(`${BASE_URL}/users/roles/${userId}/${id}`, {
     method: "PUT", headers: {
      "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     },
     body: JSON.stringify({name, description, statut })
   });
   return await data.json();
 },
 deleteRole:async (token,id)=>{
  const data = await fetch(`${BASE_URL}/users/roles/${id}`, {
     method: "DELETE", headers: {
       "Content-Type": "application/json",
       'Authorization': `Bearer ${token}`
     }
   });
   return await data.json();
 },

};

export default AuthService;
