
import React, { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import "../assets/styles/style.css";

const BraidsForm = ({ allBraids,selectedDish,onDishClick }) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
  
    useEffect(() => {
        if (allBraids && allBraids.length > 0 && !selectedDish) {
          onDishClick(allBraids[0].id);
        }
      }, [allBraids]);
      
  
  
    return (
      <>
        {allBraids && allBraids.length > 0 ? (
          allBraids.map((braid) => {
            const priceStyle = braid.pricePromo 
              ? { textDecoration: "line-through", color: "#F60202" } 
              : {};
              const imgSrc= braid.image && (`data:image;base64,${braid.image}`);
              return(
<div
              key={braid.id}
              className={`dish ${selectedDish && selectedDish.id === braid.id ? "selected" : ""}`}
              style={{ width: isSmallScreen ? "300px" : '', padding: isSmallScreen ? '18px' : '8px' }}
              onClick={() => onDishClick(braid.id)}
            >
              {braid.isPromo && <div className="promotion-label">Promo</div>}
              <img src={`${imgSrc}`} alt="" />
              <h6>{braid.name}</h6>
              <h6 style={priceStyle}>${braid.priceNormal}</h6>
              {
                braid.pricePromo && (
                    <h6>${braid.pricePromo}</h6>
                )
              }
             
              <h6>{braid.statut === 'available' ? 'Available' : braid.statut === 'out_of_stock' ? 'Out Of Stock' : 'Discontinued'}</h6>
            </div>
              );
            
})
        ) : (
          <p>No braids available</p>
        )}
      </>
    );
  };
export default BraidsForm;