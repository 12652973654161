import React, { useState,useRef,useEffect } from "react";
import Btn from "../../components/button/btnWithIcon";
import procederIcon from "../../assets/icons/proceder-icon.svg"
import imprimerIcon from "../../assets/icons/imprimer-icon.svg"
import fleche from "../../assets/icons/fleche.svg";
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../assets/styles/style.css';
import { colors } from "../../assets/config/color";
import Invoice from "../../components/Invoice";
import DateFormatter from "../../components/Formatter/DateFormatter";
import { useReactToPrint } from "react-to-print";
import AuthService from "../../services/AuthServices";
import { ToastContainer, toast } from "react-toastify";
function RightbarOrder({choiceOrder,onOrderCompleted}){
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [showDetails,setShowDetails]=useState(null);
  const toastId = useRef(null);
  const invoiceRef = useRef();
  const [token, setToken] = useState();
  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const getUserData = () => {
    const tok = localStorage.getItem("token");
    setToken(tok);
  };
  useEffect(() => {
    getUserData();
  }, []);
  const handleComplitedOrder = async() => {
    try{
      const response = await AuthService.completedOrder(token,choiceOrder.id);
 
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    if (onOrderCompleted) {
      onOrderCompleted();
    }
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error);
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Error when submitting" );
    }
  }
  };
  if (!choiceOrder) {
    return <div className="right-sidebar" style={{width:isSmallScreen?'100%':'35%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>Please select an order to view details.</div>;
  }
  return (
    <div className="right-sidebar" style={{width:isSmallScreen?'100%':'35%',height:'100%'}}>
       <div style={{paddingLeft:"12px",paddingTop:"12px",paddingRight:"12px"}}>
        <div className="titleC">Order</div>
        <div className="titleC1">
        <h6>{choiceOrder && choiceOrder.fullOrderId}</h6>
        <h6>#{choiceOrder && choiceOrder.place_name}</h6>
        <h6>{choiceOrder && DateFormatter(choiceOrder.date)}</h6>
        </div>
        </div>
        <div style={{borderBottom: "0.5px solid rgba(159, 159, 158 ,0.5)",paddingBottom: "0.5px", opacity:0.5}} />
      <div className="pad">
        <div className="vente column">
          <div className="row">
          <div className="icon-and-title">
        <img src={fleche} alt="" style={{paddingLeft:"6px"}} onClick={()=>setShowDetails(!showDetails)} />
        <h5>{choiceOrder && choiceOrder.quantity+' '+choiceOrder.braid_name}</h5>
        </div>
        <h5>{choiceOrder && choiceOrder.price} $</h5>
          </div>
      
        {
          showDetails && (
            <div className="pad">
              <div className="row">
                <h6>Quantity</h6>
                <h6>{choiceOrder && choiceOrder.quantity}</h6>
                </div>
                  <div className="row">
                    <h6>Subtotal</h6>
                    <h6>{choiceOrder && choiceOrder.price*choiceOrder.quantity} $</h6>
                    </div>
                    <div className="row">
                      {
                        choiceOrder && choiceOrder.pricePromo &&
                        <>
                          <h6>Discount</h6>
                          <h6>{choiceOrder.pricePromo - choiceOrder.price} $</h6>
                        </>
                         
                        
                      }
                      </div>
                      <div className="row">
                    <h6>status</h6>
                    <h6 style={{ color:choiceOrder && choiceOrder.order_status==='validated'?"#09AA29":choiceOrder.order_status==='pending'?colors.orange:choiceOrder.order_status === 'completed'?'#673AB7':'red' }}>{choiceOrder && choiceOrder.order_status}</h6>
                    </div>
                      <div className="row">
                        <h6>Total</h6>
                        <h6>  {choiceOrder &&
                    (choiceOrder.pricePromo ? (
                      <h4>
                        {choiceOrder.pricePromo}
                        <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                      </h4>
                    ) : (
                      <h4>
                        {choiceOrder.price}
                        <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                      </h4>
                    ))}
                        </h6>
                        </div>
                        </div>
                        )

        }
        </div>
        
      </div>
      <footer>
      <div className="footerR">
      {choiceOrder && choiceOrder.pricePromo && (
                <>
                  <h5>Discount Coupon</h5>
                  <h5 id="h5">
                    <strong style={{ color: colors.gold, fontSize: 18 }}>
                      {(
                        (1 - choiceOrder.pricePromo / choiceOrder.price) *
                        100
                      ).toFixed(2)}
                    </strong>
                    %
                  </h5>
                </>
              )}
        </div>
        <div className="bottomR">
        <div className="bottomR1"> 
        <div className="row">
            <h4>Subtotal</h4>
            {choiceOrder && (
                    <h4>
                      {choiceOrder.price}
                      <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                    </h4>
                  )}
          </div>
          <div className="row">
            <h4>Tax</h4>
            <h4>00 <strong style={{fontFamily:"inter-bold"}}>$</strong></h4>
          </div>
          <div className="row">
          {choiceOrder && choiceOrder.pricePromo && (
                    <>
                      <h4>Discount</h4>
                      <h4>
                        <strong style={{ color: colors.gold }}>
                          {choiceOrder.pricePromo - choiceOrder.price}
                        </strong>
                        <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                      </h4>
                    </>
                  )}
             </div>
          <div className="row">
            <h4><strong style={{fontFamily:"inter-bold"}}>Total Amount</strong></h4>
            {choiceOrder &&
                    (choiceOrder.pricePromo ? (
                      <h4>
                        {choiceOrder.pricePromo}
                        <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                      </h4>
                    ) : (
                      <h4>
                        {choiceOrder.price}
                        <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                      </h4>
                    ))}
          </div>
        </div>
          <div className="row">
            <Btn txtbtn={"Invoice"} bgcolor={"168, 102, 127"} txtcolor={'white'} iconbtn={imprimerIcon} action={handlePrint} disabled={choiceOrder.order_status !== 'completed'?true:false} />
            <Btn txtbtn={"Proceed"} bgcolor={"9, 170, 41"} txtcolor={'white'} iconbtn={procederIcon} action={handleComplitedOrder} disabled={choiceOrder.order_status === 'completed'?true:false} />
          </div>
        </div>
        <div style={{ display: "none" }}>
        <Invoice ref={invoiceRef} choiceOrder={choiceOrder} />
      </div>
      </footer>
      <ToastContainer position='top-center' limit={1} />
    </div>
  );
};

export default RightbarOrder;



