// injectColors.js
import { colors } from "./color";

const injectColors = () => {
  const root = document.documentElement;
  Object.keys(colors).forEach(key => {
    root.style.setProperty(`--${key}`, colors[key]);
  });
};

export default injectColors;
