import React from "react";

function order({ fill }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="30"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M2406 5104 c-153 -37 -300 -152 -366 -286 l-33 -68 -201 0 c-278 0
-286 -5 -286 -196 l0 -104 -252 0 c-188 0 -269 -4 -316 -15 -192 -46 -352
-206 -391 -393 -8 -38 -11 -576 -11 -1846 0 -1758 0 -1795 20 -1858 50 -161
189 -282 373 -323 64 -14 238 -15 1652 -13 l1580 3 67 26 c123 48 231 136 278
228 53 105 50 -2 50 1941 0 1963 3 1844 -57 1970 -35 73 -150 187 -225 223
-103 49 -160 57 -435 57 l-253 0 0 104 c0 122 -8 149 -52 176 -30 18 -51 20
-240 20 l-207 0 -32 68 c-44 91 -150 197 -241 241 -121 57 -295 76 -422 45z
m284 -244 c100 -51 157 -119 187 -220 35 -122 53 -130 292 -130 l181 0 0 -245
0 -245 -790 0 -790 0 0 244 0 245 184 3 c221 4 226 6 267 114 38 102 53 127
109 177 73 66 135 88 235 84 59 -3 92 -10 125 -27z m-1168 -822 c3 -160 5
-175 25 -204 12 -17 35 -37 50 -43 21 -8 279 -11 975 -9 l946 3 27 25 c48 44
55 76 55 246 l0 156 258 -4 257 -3 57 -28 c61 -30 95 -64 131 -131 l22 -41 3
-1775 c2 -1378 0 -1785 -10 -1820 -15 -57 -78 -121 -144 -146 -47 -18 -102
-19 -1588 -22 -1674 -3 -1605 -5 -1695 52 -47 30 -75 67 -90 121 -9 30 -11
490 -9 1815 3 1711 4 1776 22 1810 48 91 112 143 197 159 24 5 148 9 276 10
l231 1 4 -172z"
        />
        <path
          d="M1955 3396 c-16 -8 -118 -98 -225 -200 -107 -102 -198 -186 -201
-186 -4 0 -42 37 -85 83 -98 103 -118 117 -172 117 -74 0 -122 -66 -107 -147
5 -30 33 -64 152 -184 80 -81 158 -153 175 -160 59 -24 86 -6 353 247 243 230
285 277 285 319 0 35 -35 88 -71 107 -40 21 -63 22 -104 4z"
        />
        <path
          d="M2384 3094 c-40 -19 -64 -62 -64 -114 0 -57 47 -105 111 -115 24 -3
359 -5 744 -3 762 3 729 1 765 61 37 59 21 124 -39 164 l-34 23 -726 0 c-626
0 -730 -3 -757 -16z"
        />
        <path
          d="M1945 2418 c-11 -6 -108 -96 -216 -200 l-196 -188 -93 95 c-97 99
-137 122 -189 110 -61 -13 -103 -86 -87 -151 7 -27 270 -309 308 -331 29 -16
87 -16 114 0 44 25 522 489 534 518 17 41 -1 99 -41 133 -33 28 -97 35 -134
14z"
        />
        <path
          d="M2384 2120 c-82 -33 -85 -173 -4 -215 44 -23 1466 -23 1514 0 60 29
83 103 51 165 -32 62 -2 60 -807 59 -403 0 -743 -4 -754 -9z"
        />
        <path
          d="M1955 1445 c-16 -7 -118 -97 -225 -199 -107 -102 -198 -186 -201
-186 -3 0 -41 37 -85 83 -100 104 -119 117 -172 117 -74 0 -122 -66 -107 -147
5 -30 33 -64 152 -184 80 -81 158 -153 175 -160 60 -25 86 -6 353 247 243 230
285 277 285 319 0 35 -35 88 -71 107 -40 21 -63 22 -104 3z"
        />
        <path
          d="M2394 1150 c-11 -4 -33 -22 -47 -40 -56 -66 -23 -166 61 -190 56 -15
1426 -13 1472 3 19 7 44 27 57 46 40 60 26 126 -36 168 l-34 23 -726 -1 c-399
0 -735 -4 -747 -9z"
        />
      </g>
    </svg>
  );
}

export default order;
