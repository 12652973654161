import React,{useState,useEffect} from "react";
import { useNavigate ,useLocation} from 'react-router-dom';
import "../../assets/styles/style.css";
import img from '../../assets/images/photo.png';
import  logoutIcon from "../../assets/icons/logout-icon.svg";
import  Order from "../../assets/icons/order";
import AuthService from "../../services/AuthServices";
import Booking from'../../assets/icons/booking';
import { FaHome } from 'react-icons/fa';
import Braiding from "../../assets/icons/braiding";
import { colors } from "../../assets/config/color";
const Sidebar = () => {
  const location = useLocation();
  const [activeScreen, setActiveScreen] = useState('/');
  useEffect(() => {
    setActiveScreen(location.pathname);
  }, [location.pathname]);
  const navigate = useNavigate();
 const handleLogout =()=>{
  AuthService.logout();
  navigate('/');
 }
  return (
    <div id="sidebar">
      <MenuItem path="/home" icon={<FaHome size={24} style={{ color: activeScreen === '/home'?colors.gold :colors.gris }} />} text="Home" active={activeScreen === '/home'} />
      <MenuItem path="/menu" icon={<Braiding  fill = {activeScreen === '/menu'?colors.gold :colors.gris } />} text="Braiding" active={activeScreen === '/menu'} />
      <MenuItem path="/table" icon={<Booking fill={ activeScreen === '/table'?colors.gold :colors.gris} />} text="Booking" active={activeScreen === '/table'} />
      <MenuItem path="/order" icon={<Order  fill={activeScreen === '/order'?colors.gold :colors.gris } />} text="Order" active={activeScreen === '/order'} />
       <div style={{marginTop:'auto'}}>
       <div className="item-menu item-menunotfocus photo">
        <img src={img} alt=""/>
      </div>
      <div className="item-menu item-menunotfocus logout" onClick={() => handleLogout()}>
        <img src={logoutIcon} alt="" />
        <span>Logout</span>
      </div>
       </div>
       
     
      
    </div>
  );
}

const MenuItem = ({ path, icon, text, active }) => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  
  return (
    <div className={`item-menu ${active ? 'item-menufocus ' : 'item-menunotfocus '}`} onClick={() => handleNavigation(path)}>
      {icon}
      <span style={{ color: active ? colors.gold :''}}>{text}</span>
    </div>
  );
}


export default Sidebar;
