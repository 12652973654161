import React,{useState} from "react";
import "../assets/styles/style.css";
import LogoLogin from "../assets/logos/log.jpg";
import Input from "../components/input/input";
import Btn from "../components/button/btnWithoutIcon";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../stores/config';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
function ResetPassword(props) {
    const [email, setEmail] = useState("");
    const [isloading,setIsloading]=useState(false);
    const toastId = React.useRef(null);
    const usernamechange = (event) => {
        setEmail(event.target.value);
      };
      const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleResetPassword = async (email) => {
    setIsloading(true);
    try {
      if (!email) {
        if(! toast.isActive(toastId.current)) {
          toastId.current = toast.error("Une adresse e-mail valide est requis.");
        }
        return;
      }
      const Regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/; 
      if (!email.match(Regex)) {
        if(! toast.isActive(toastId.current)) {
          toastId.current = toast.error("Veuillez renseigner une adresse e-mail valide !");
        }
          return ;
      } 
      const response = await axios.post(`${BASE_URL}/users/resetPassword`,{email});
      console.log(response.status);
      if (response.status === 200) {
        if(! toast.isActive(toastId.current)) {
            toastId.current =  toast.success(response.data.message);
          }
          setEmail('');
      }
    }
    catch (error) {
        if (error.response) {
            if(! toast.isActive(toastId.current)) {
                toastId.current =  toast.error(error.response.data.error);
              }
        }
        else{
            if(! toast.isActive(toastId.current)) {
                toastId.current =  toast.error("Une erreur s'est produite, veuillez verifier votre connexion et reessayez");
                }
        }
      
    }
    finally{
      setIsloading(false);
    }
  };
  return (
    <div className="containerR">
        <img src={LogoLogin} alt="" width={100} height={100} />
        <div>
          <h3 style={{color:'rgba(242, 79, 4)'}}>Mot de passe oublié</h3>
          <form>
          <br/>
            <div>
              <label className="label" style={{color:'black'}}>Email</label>
            </div>
            <br/>
            <div>
            <Input
                placeholder={"adresse@mail.com"}
                onChange={(event) => usernamechange(event)}
                value={email}
              />
            </div> <br/>
            <Btn
                txtbtn={ isloading?'En cours ...':"Envoyer"}
                bgcolor={"242, 79, 4"}
                bgopacity={isloading?0.5:1}
                txtcolor={"#fff"}
                action={()=>handleResetPassword(email)}
              />
              <br/>
              <div className="rowEnd" onClick={()=> handleNavigation('/')}>
                <span style={{color:'#09AA29',textDecoration:'underline'}}>Se connectez ?</span>
              </div>
          </form>
        </div>
        <ToastContainer limit={1} position="top-center" />
    </div>
  );
}

export default ResetPassword;
