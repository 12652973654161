import React from 'react';
import './ConfirmDialog.css'; 

const ConfirmDialog = ({ message, onConfirm, onCancel,texConfirm,textCancel }) => {
  return (
    <div className="confirm-dialog">
      <div className="confirm-dialog-contain">
        <p>{message}</p>
        <div className="rowL">
          <button className="button confirm" style={{ backgroundColor: 'red' }} onClick={onConfirm}>
            {texConfirm}
          </button>
          <button className="button cancel" onClick={onCancel}>
          {textCancel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
