import React from 'react';

function braiding({fill}) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="35" height="35" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
       
       <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill={fill} stroke="none">
       <path d="M1465 5106 c-344 -84 -465 -518 -214 -769 225 -225 599 -159 737 130
       36 76 37 81 37 193 0 112 -1 117 -37 193 -96 200 -312 305 -523 253z"/>
       <path d="M1007 4716 c-120 -50 -187 -118 -228 -231 -17 -49 -23 -95 -29 -255
       -9 -213 -19 -274 -55 -336 -30 -51 -64 -74 -122 -82 -54 -9 -58 -22 -13 -45
       54 -28 170 -19 238 18 73 40 144 118 170 187 25 67 42 190 42 308 0 106 15
       203 40 255 14 29 19 62 19 123 0 45 -1 82 -2 82 -1 0 -28 -11 -60 -24z"/>
       <path d="M3319 4287 c-77 -26 -167 -90 -211 -150 -177 -241 -98 -568 168 -694
       67 -32 90 -38 167 -41 110 -6 194 15 275 68 200 128 267 377 161 597 -34 72
       -135 167 -216 205 -61 29 -79 33 -173 36 -89 2 -115 -1 -171 -21z"/>
       <path d="M1115 4145 c-27 -8 -52 -14 -56 -15 -3 0 -11 -34 -18 -75 -7 -41 -25
       -101 -42 -133 -27 -54 -108 -144 -152 -168 -18 -10 -18 -16 -3 -124 20 -146
       20 -319 0 -418 -24 -118 -77 -267 -148 -419 -93 -196 -104 -269 -56 -368 11
       -22 114 -295 229 -606 l209 -565 76 -540 c45 -321 83 -554 93 -577 31 -71 115
       -127 193 -127 112 0 209 97 210 210 0 24 -70 538 -155 1141 -87 617 -155 1129
       -155 1170 0 96 24 250 56 369 15 52 28 106 31 120 3 19 -40 91 -182 310 -102
       157 -190 301 -196 322 -17 55 -3 122 34 165 85 97 223 94 296 -7 16 -22 98
       -147 182 -277 84 -129 158 -237 164 -239 6 -3 139 54 296 126 156 73 301 135
       323 138 38 7 38 7 18 25 -38 35 -86 31 -382 -27 -157 -31 -292 -58 -301 -60
       -12 -3 -36 46 -104 213 -132 321 -148 355 -187 384 -71 54 -188 77 -273 52z"/>
       <path d="M1165 3822 c-16 -11 -38 -29 -49 -40 -23 -27 -32 -101 -16 -133 30
       -59 435 -677 460 -703 22 -22 40 -30 78 -34 48 -4 67 3 404 158 194 89 372
       173 395 186 84 49 93 157 18 223 -63 55 -81 50 -436 -113 l-314 -144 -102 156
       c-56 86 -140 217 -187 289 -48 73 -98 140 -111 149 -39 26 -105 28 -140 6z"/>
       <path d="M2908 3725 c-61 -41 -79 -99 -35 -111 19 -4 23 -11 19 -29 -3 -19 2
       -27 20 -36 20 -9 34 -7 77 11 28 12 51 23 51 24 0 0 -11 23 -25 50 -13 27 -27
       64 -31 82 -8 41 -25 43 -76 9z"/>
       <path d="M2708 3589 c-54 -28 -75 -79 -33 -79 11 0 13 -8 9 -29 -5 -24 -2 -30
       15 -36 44 -14 132 19 152 57 10 18 8 24 -9 38 -15 13 -18 20 -10 28 19 19 -10
       42 -51 42 -20 -1 -53 -10 -73 -21z"/>
       <path d="M2482 3559 l-32 -10 35 -31 c20 -17 43 -44 51 -59 16 -32 30 -35 69
       -17 25 11 34 41 15 53 -8 5 -7 11 1 21 30 36 -66 65 -139 43z"/>
       <path d="M3248 3295 c-103 -39 -190 -139 -228 -262 -24 -77 -25 -154 -6 -278
       43 -275 20 -449 -100 -730 -31 -71 -67 -157 -81 -190 -37 -86 -42 -176 -13
       -243 25 -57 86 -119 150 -152 23 -12 238 -73 477 -135 240 -63 437 -116 439
       -118 3 -2 71 -243 153 -536 161 -578 162 -581 245 -624 126 -66 271 3 306 144
       9 37 8 57 -5 109 -54 208 -318 1141 -332 1173 -9 21 -33 53 -53 71 -39 35 -37
       35 -493 155 -422 112 -465 129 -501 205 -14 30 -16 92 -16 513 0 442 2 481 19
       518 50 110 182 146 278 76 78 -56 78 -61 76 -451 l-3 -345 34 130 c47 186 40
       172 85 172 88 0 162 87 169 197 l4 62 -61 77 c-33 42 -90 107 -126 145 -72 77
       -113 154 -122 232 -5 47 -9 53 -47 72 -53 28 -192 35 -248 13z"/>
       <path d="M2288 3131 c-32 -14 -58 -30 -58 -34 0 -4 9 -3 19 3 16 8 22 6 29 -6
       12 -21 36 -13 57 20 27 40 14 45 -47 17z"/>
       <path d="M2211 3026 c-30 -36 -21 -157 14 -186 13 -10 19 -10 34 4 13 11 21
       14 26 7 25 -41 61 32 50 102 -12 71 -55 115 -72 72 -5 -14 -7 -14 -23 0 -15
       14 -19 14 -29 1z"/>
       <path d="M3320 2962 c-19 -9 -45 -32 -57 -51 -23 -34 -23 -38 -25 -415 -4
       -576 -4 -570 15 -600 10 -14 27 -32 40 -40 31 -21 204 -72 561 -165 170 -45
       324 -90 342 -102 19 -11 49 -38 67 -60 30 -34 36 -37 47 -24 7 8 15 35 17 60
       17 144 -223 310 -660 457 l-159 53 4 405 3 405 -24 34 c-25 35 -74 61 -113 61
       -13 0 -39 -8 -58 -18z"/>
       <path d="M2318 2824 c-3 -5 9 -54 28 -109 44 -134 53 -231 27 -308 -12 -35
       -16 -60 -10 -64 28 -17 50 82 44 197 -6 121 -68 319 -89 284z"/>
       <path d="M2274 2697 c-2 -175 -51 -329 -142 -443 -37 -47 -43 -64 -21 -64 16
       0 102 132 129 198 16 37 36 102 44 144 20 95 22 278 4 278 -10 0 -13 -30 -14
       -113z"/>
       <path d="M2226 2778 c-3 -13 -6 -34 -6 -48 0 -41 -28 -137 -57 -196 -30 -61
       -87 -118 -139 -140 -41 -17 -46 -39 -6 -29 67 16 143 91 182 180 22 51 50 173
       50 219 0 40 -14 48 -24 14z"/>
       <path d="M2700 1443 c-22 -8 -40 -34 -40 -58 0 -29 28 -53 65 -57 23 -2 29 -8
       28 -23 -2 -11 -46 -292 -99 -623 -52 -332 -93 -610 -91 -616 6 -15 89 -29 97
       -16 3 5 18 90 34 189 l28 181 394 0 c217 0 394 -3 394 -7 0 -17 59 -365 62
       -368 2 -2 24 -1 51 2 37 5 47 9 47 24 0 21 -178 1148 -184 1165 -3 9 -106 39
       -111 32 -2 -3 96 -621 110 -695 l6 -33 -375 0 -375 0 5 33 c3 17 31 194 63
       392 l57 360 140 5 139 5 -60 13 c-66 15 -151 50 -195 82 -22 16 -43 20 -102
       19 -40 0 -80 -3 -88 -6z"/>
       </g>
       </svg>
    );
}

export default braiding;