import React from 'react';
import Header from '../layout/header/header';
import Sidebar from '../layout/sidebar/sidebar';
import MenuCenter from '../layout/center/braiding';
import "../assets/styles/style.css";
function Menu() {
    return (
        <div className='container' >
        <Header/>
        <Sidebar/>
        <MenuCenter/>
      </div>
    );
}

export default Menu;