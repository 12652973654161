import React from 'react';
import './btn.css';

function btnWithoutIcon({txtbtn,bgcolor,txtcolor,bgopacity=1,action=null}){
    return (
        <div className='rowbtn1' style={{backgroundColor: `rgba(${bgcolor}, ${bgopacity})`}} onClick={()=>action()}>
            <span style={{color:txtcolor}}>
                {txtbtn}
            </span>
        </div>
    );
}

export default btnWithoutIcon;