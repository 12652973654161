
const NumberFormatter =(nbr)=> {
     if (nbr >= 1000 && nbr < 1000000) {
        return nbr/1000 + "K";
        }
     else if (nbr >= 1000000 && nbr < 1000000000) {
        return nbr/1000000 + "M";
            }
      else if (nbr >= 1000000000) {
        return  nbr/1000000000 + "B";
                }
      else {
         return   nbr;
            }
}

export default NumberFormatter;