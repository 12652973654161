import React from 'react';
import './btn.css';

function Btn({ txtbtn, bgcolor, txtcolor, bgopacity = 1, iconbtn, action = null, disabled = false }) {
    return (
        <div 
            className={`rowbtn ${disabled ? 'disabled' : ''}`} 
            style={{ backgroundColor: `rgba(${bgcolor}, ${bgopacity})`, pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.5 : 1 }}
            onClick={() => !disabled && action()}
        >
            <img src={iconbtn} alt="" style={{ marginRight: '5px' }} />
            <span style={{ color: txtcolor }}>
                {txtbtn}
            </span>
        </div>
    );
}

export default Btn;
