import React, { useState , useEffect,useRef} from "react";
import "../../assets/styles/style.css";
import RightbarOrder from "../rightbar/rightbarOrder";
import useMediaQuery from "@mui/material/useMediaQuery";
import { colors } from "../../assets/config/color";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../services/AuthServices";
import { FaEdit, FaTrash } from 'react-icons/fa';
import ConfirmDialog from "../../components/CustomDialog/ConfirmDialog";
import DateFormatter from "../../components/Formatter/DateFormatter";
const Order = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  
  const [selected, setSelected] = useState(0);
  const [orders,setOders]=useState([]);
  const [token, setToken] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [orderIds, setOrderIds] = useState({});
  const [selectedOrder,setSelectedOrder]=useState(null);
  const [isShowButton,setIsShow]=useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
 const handleShowButton =()=>{
    setIsShow(true);
  }

  const toastId = useRef(null);
  const handleItemClick = (index) => {
    setSelected(index);
  };
  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };
  const getUserData = () => {
    const tok = localStorage.getItem("token");
    setToken(tok);
  };
  useEffect(() => {
    getUserData();
  }, []);
  const handleGetOrders = async () => {
    setIsLoading(true);
    try {
      if (token) {
        const response = await AuthService.getOrders(token);
        if (response.data) {
          const newOrderIds = response.data.reduce((acc, order) => {
            const braidInitials = order.braid_name.slice(0, 2).toUpperCase();
            acc[order.id] = `#GHB${order.id}${braidInitials}${order.place_id}`;
            return acc;
          }, {});
          setOrderIds(newOrderIds);
          setOders(response.data);
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(response.error);
          }
        }
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token or User ID missing");
        }
      }
    } catch (error) {
      console.log(error);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Error while fetching Orders");
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (token) {
      handleGetOrders();
    }
  }, [token]);
  useEffect(() => {
    if (orders && orders.length > 0 && !selectedOrder) {
      setSelectedOrder({...orders[0],
        fullOrderId: `${orderIds[orders[0].id]}`});
    }
  }, [orders]);
  const handleDeleteOrder = async(_id) => {
    try{
      const response = await AuthService.deleteOrder(token,_id);
 
  if (response.message) {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(response.message);
    }
    await handleGetOrders();
  }
  else{
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(response.error);
    }
  }
  
  }
  catch (error){
    console.log(error);
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
    }
  }
    handleCloseModal();
  };
  const handleUpdateOrder = async()=>{
    try{
      const response = await AuthService.updateOrder(token,selectedOrder.id,selectedOrder.place_id,selectedOrder.order_status);
      if (response.message) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(response.message);
          }
          await handleGetOrders();
          handleCloseEditModal();
          }
          else{
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.error(response.error);
              }
              }
              }
              catch (error){
                console.log(error);
                if (!toast.isActive(toastId.current)) {
                  toastId.current = toast.error(" An occured where updating the order status" );
                  }
                  }

  }
  return (
    <div className={`${isSmallScreen ? "main-contentHR" : "main-contentH"}`}>
      <div
        className={`${isSmallScreen ? "column" : "row"}`}
        style={{ flex: 1 }}
      >
        <div
          className="main-content"
          style={{ width: isSmallScreen ? "100%" : "65%" }}
        >
          <div className="padding">
            <div className="table">
              <div className="caption"> Orders</div>
              <div className="rowT command-titleth">
                <div className="cell1">ID Order</div>
                <div className="cell1">Place</div>
                <div className="cell1">Status</div>
                <div className="cell1">Total</div>
              </div>
              {isLoading ? (
              <div className="loader"></div>
               ) : (orders && orders.length > 0 ?
                (
                   orders.map((order,index)=>(
                    <div className={`rowT command-titletd ${ selected === index ? "selected" : "" } `}
                    onClick={() => {handleItemClick(index);setSelectedOrder({
                      ...order,
                      fullOrderId: orderIds[order.id],
                    });
                    handleShowButton()
                  }}
                  >
                    <div className="cell">{orderIds[order.id]}</div>
                    <div className="cell">{order.place_name}</div>
                    <div className="cell" style={{ color: order.order_status==='validated'?"#09AA29":order.order_status==='pending'?colors.orange:order.order_status === 'completed'?'#673AB7':'red' }}>
                    { order.order_status.charAt(0).toUpperCase() + order.order_status.slice(1)}
                    </div>
                    <div className="cell">{order.price}$</div>
                    {selected === index && isShowButton && (
                      <div className="order-icon">
                      <FaEdit
              size={15}
              className="icon-edit icon-small"
              title="Edit"
              style={{ marginRight: '16px' }}
              onClick={()=>handleOpenEditModal()}
              
            />
            <FaTrash
              size={15}
              className="icon-delete icon-small"
              title="Delete"
              onClick={()=>handleOpenDeleteModal()}
              
            /></div>
                    )}
                  </div>
                   ))
                ):( <div style={{flex:1,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',height:'100%'}}>
                <p>No places available</p>
              </div>)
                )
                }
            </div>
          </div>
        </div>
        {isDeleteModalOpen && (
          <ConfirmDialog
            message={`Are you sure you want to Order (${selectedOrder.fullOrderId})?`}
            onConfirm={()=>handleDeleteOrder(selectedOrder.id)}
            onCancel={handleCloseModal}
            texConfirm={"YES"}
            textCancel={"NO"}
          />
        )}

{isEditModalOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <div className="form">
                <div className="row">
                  <div className="form-group">
                    <label>EDIT ORDER</label>
                  </div>
                  <div className="form-group">
                    <button className="close-button" onClick={handleCloseEditModal}>
                      X
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group" style={{ width: "45%" }}>
                    <label htmlFor="name">ORDER ID:</label>
                    <input  type="text"
                    value={selectedOrder.fullOrderId}
                    readOnly
                    />
                  </div>
                  <div className="form-group" style={{ width: "45%" }}>
                    <label htmlFor="seats">Place:</label>
                    
                    <input  type="text"
                    value={selectedOrder.place_name}
                    readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group" style={{ width: "45%" }}>
                    <label htmlFor="name">START TIME:</label>
                    <input  type="text"
                    value={selectedOrder.startTime}
                    readOnly
                    />
                  </div>
                  <div className="form-group" style={{ width: "45%" }}>
                    <label htmlFor="seats">END TIME:</label>
                    
                    <input  type="text"
                    value={selectedOrder.endTime}
                    readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group" style={{ width: "45%" }}>
                    <label htmlFor="name">PRICE:</label>
                    <input  type="text"
                    value={(selectedOrder.pricePromo ? selectedOrder.pricePromo : selectedOrder.price)+'$'}
                    readOnly
                    />
                  </div>
                  <div className="form-group" style={{ width: "45%" }}>
                    <label htmlFor="seats">DATE:</label>
                    
                    <input  type="text"
                    value={DateFormatter(selectedOrder.date) }
                    readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div style={{ width: "45%" }}>
                    <div className="form-group">
                      <label htmlFor="reservation">Item:</label>
                      
                      <input  type="text"
                    value={selectedOrder.braid_name}
                    readOnly
                    />
                    </div>
                  </div>
                  <div style={{ width: "45%" }}>
                  <div className="form-group">
                      <label>STATUS:</label>
                      <div className="checkbox-group">
                        <label>
                          <input
                            type="radio"
                            name="status"
                            value="pending"
                            checked={selectedOrder.order_status === "pending"}
                            onChange={(e) => setSelectedOrder({...selectedOrder,order_status:e.target.value})}
                          />
                          Pending
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="status"
                            value="validated"
                            checked={selectedOrder.order_status === "validated"}
                            onChange={(e) => setSelectedOrder({...selectedOrder,order_status:e.target.value})}
                         
                          />
                          Validated
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="status"
                            value="canceled"
                            checked={selectedOrder.order_status === "canceled"}
                            onChange={(e) => setSelectedOrder({...selectedOrder,order_status:e.target.value})}
                         
                          />
                          Canceled
                        </label>
                      </div>
                    </div>
                    </div>
                </div>
                <div className="rowL">
                  <button className="button" onClick={()=>handleUpdateOrder()}>
                    SAVE CHANGES
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <RightbarOrder choiceOrder={selectedOrder}
        onOrderCompleted={handleGetOrders} 
        />
      </div>
      <ToastContainer position='top-center' />
    </div>
  );
};

export default Order;
