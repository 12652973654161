import React, { useState,useEffect,useRef } from "react";
import AuthService from "../../services/AuthServices";
import Btn from "../../components/button/btnWithIcon";
import procederIcon from "../../assets/icons/proceder-icon.svg";
import "../../assets/styles/style.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { colors } from "../../assets/config/color";
import DateFormatter from "../../components/Formatter/DateFormatter";
import { ToastContainer, toast } from "react-toastify";
import ConfirmDialog from "../../components/CustomDialog/ConfirmDialog";
function RightbarMenu({ ChoiceBraid }) {
  const toastId = useRef([]);
  const [showItem, setShowItem] = useState(true);
  const [showItem2, setShowItem2] = useState(false);
  const [isPromo, setIsPromo] = useState(false);
const [promoPrice, setPromoPrice] = useState(null);
const [braidName,setBraidName]=useState(null);
const [braidDescription,setBraidDescription]=useState('');
const [priceNormal,setPriceNormal]=useState();
const [selectedStatut,setSelectedStatut]=useState('available');
const [braid_type_id,setBraidTypeId]=useState();
const [braidId,setBraidId]=useState();
const [allTypes, setAllTypes] = useState([]);
const [userId ,setUserId]=useState();
const [token, setToken]=useState();
const [selectedImage, setSelectedImage] = useState(null);
const [imagePreview, setImagePreview] = useState(null);
const [confirmDeleteBraid,setConfirmDeleteBraid]=useState(null);

const handleSelectedStatutChange=(statut) =>{
  setSelectedStatut(statut);
}
const handleImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      const base64String= reader.result.replace('data:', '').replace(/^.+,/, '');
      setSelectedImage(base64String);
    };
    reader.readAsDataURL(file); 
  }
};
  const handlePromoCheckboxChange = (event) => {
    setIsPromo(event.target.checked);
    if (!event.target.checked) {
      setPromoPrice();
    }
  };

  const handlePromoPriceChange = (event) => {
    setPromoPrice(event.target.value);
  };
  const handleBraidDescriptionChange = (event) => {
    setBraidDescription(event.target.value);
  };
  const handlePriceNormalChange = (event) => {
    setPriceNormal(event.target.value);
  };
  const handleBraidNameChange = (event) => {
    setBraidName(event.target.value);
    const typeObject = allTypes.find(type => type.name === braidName);
    setBraidTypeId(typeObject?typeObject.id:null);
  };
  const hideShowItem = () => {
    setShowItem(!showItem);
    setShowItem2(true);
  };
  const cancelButton = ()=>{
    setShowItem2(false);
    setSelectedImage();
    setBraidName();
    setImagePreview();
    setPriceNormal();
    setPromoPrice();
    setShowItem(true);
    setConfirmDeleteBraid(null);
  }
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const handleTypes = async()=>{
    try{
      if (token &&  userId ) {
        const response = await AuthService.getAllTypes(userId,token);
    if (response.data) {
      setAllTypes(response.data);
    }
    else{
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(response.error);
      }
    }
      }
      else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token ou User ID manquant");
        }
      }
    
    }
    catch (error){
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
      }
    }
  }
  const getUserData =()=>{
    const tok =  localStorage.getItem('token');
    const id = localStorage.getItem('id');
    setToken(tok);
    setUserId(id);
  }
  const handleUpdateType = async()=>{
    try{
      if (!braidName) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please Choose the name of the Braid, it's require!");
        }
        return;
      }
      if (!priceNormal || priceNormal <= 0) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("PriceNormal is required and must be a positive number.");
        }
        return;
      }
      if (isPromo) {
        if (!promoPrice || promoPrice <= 0) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Price Promo is required and must be a positive number.");
        }
        return;
      }
      if (priceNormal <= promoPrice) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Promotional price must be less than the normal price and must be a positive number.");
        }
        return;
      }
      }
      if (!isPromo) {
        setPromoPrice(null);
      }
      console.log('promoPrice :'+promoPrice);
      console.log(isPromo);
        const response = await AuthService.updateBraid(userId,token,braidId,braid_type_id,braidName,braidDescription,priceNormal,promoPrice,isPromo,selectedStatut,selectedImage)
     console.log(response);
    if (response.message) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(response.message);
      }
      cancelButton();
      // window.location.reload();
    }
    else{
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(response.error);
      }
    }
    
    }
    catch (error){
      console.log(error)
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
      }
    }
  };
  const handleDeleteBraid = async(_id)=>{ 
    try{
        const response = await AuthService.deleteBraid(userId,token,_id);
   
    if (response.message) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(response.message);
      }
      cancelButton();
      window.location.reload();
    }
    else{
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(response.error);
      }
    }
    
    }
    catch (error){
      console.log(error);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Une erreur s'est produite lors de la soumission du formulaire" );
      }
    }
  }
  const style ={
    border:'none',backgroundColor:'#fff',padding:'0',paddingLeft:'8px',color: '#9f9f9e'
  }
const [users,setUsers]=useState([]);
const [user, setUser] = useState("");
const handleUsers = async()=>{
try{
if (token) {
  const response = await AuthService.getEmployers(token);
if (response.data) {
setUsers(response.data);
}
else{
if (!toast.isActive(toastId.current)) {
  toastId.current = toast.error(response.error);
}
}
}
else {
  if (!toast.isActive(toastId.current)) {
    toastId.current = toast.error("Token ou User ID manquant");
  }
}

}
catch (error){
console.log(error);
if (!toast.isActive(toastId.current)) {
  toastId.current = toast.error("Error Where filtering Employers" );
}
}

}
const handleUserChange = (event) => {
setUser(event.target.value);
};
const [startTime, setStartTime] = useState("00:00");
    
      const incrementTime = (time, setTime) => {
        const [hours, minutes] = time.split(':').map(Number);
        let newMinutes = minutes + 1;
        let newHours = hours;
    
        if (newMinutes === 60) {
          newMinutes = 0;
          newHours = (newHours + 1) % 24;
        }
    
        setTime(`${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`);
      };
    
      const decrementTime = (time, setTime) => {
        const [hours, minutes] = time.split(':').map(Number);
        let newMinutes = minutes - 1;
        let newHours = hours;
    
        if (newMinutes === -1) {
          newMinutes = 59;
          newHours = (newHours - 1 + 24) % 24;
        }
    
        setTime(`${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`);
      };
  const handleDeleteBraidClick = (braid) => {
    setConfirmDeleteBraid({
      message: `Are you sure you want to delete this Braid "${braid.name}"?`,
      onConfirm: () => handleDeleteBraid(braid.id)
    });
  };
  useEffect(() => {
    getUserData();
  }, []); 
  
  useEffect(() => {
    if (token && userId) {
      handleTypes();
      handleUsers();
    }
      
  }, [userId,token]);
  useEffect(() => {
    if (ChoiceBraid) {
      setBraidName(ChoiceBraid.name);
      setBraidDescription(ChoiceBraid.description || '');
      setPromoPrice(ChoiceBraid.pricePromo || null);
      setPriceNormal(ChoiceBraid.priceNormal);
      setSelectedImage(ChoiceBraid.image);
      setImagePreview(ChoiceBraid.image);
      setIsPromo(ChoiceBraid.isPromo || false);
      setBraidId(ChoiceBraid.id);
      setUser(ChoiceBraid.employer_id);
      setStartTime(ChoiceBraid.time);
      console.log(ChoiceBraid.employer_id);
      

    }
}, [ChoiceBraid]);
if (!ChoiceBraid) {
  return <div className="right-sidebar" style={{width:isSmallScreen?'100%':'35%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>Please select an Braid to view details.</div>;
}

  return (
    <div
      className="right-sidebar"
      style={{ width: isSmallScreen ? "100%" : "35%", height: "100%" }}
    >
      {showItem && (
        <>
          <div
            style={{
              paddingLeft: "12px",
              paddingTop: "12px",
              paddingRight: "12px",
            }}
          >
            <div className="titleC">Braiding</div>
            <div className="titleC1">
              <h6>#{ChoiceBraid && ChoiceBraid.name}</h6>
              <h6>
                Pi1 . {ChoiceBraid && DateFormatter(ChoiceBraid.createdAt)}
              </h6>
            </div>
          </div>
          <div
            style={{
              borderBottom: "0.5px solid rgba(159, 159, 158 ,0.5)",
              paddingBottom: "0.5px",
              opacity: 0.5,
            }}
          />
          <div className="pad">
            <h5
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              Details
            </h5>
            <div className="venteM">
              <div className="icon-and-title">
                <h5 style={{ color: "#09AA29" }}>Braid name</h5>
              </div>
              <h5>{ChoiceBraid && (ChoiceBraid.name)}</h5>
            </div>
            <div className="venteM">
            <h5 style={{ color: "#09AA29" }}>Employer</h5>
              <div className="icon-and-title">
                
                {users.length !== 0 && ChoiceBraid && (() => {
    const selectedUser = users.find((user) => user.id === ChoiceBraid.employer_id);
    return selectedUser ? (
        <h5>{selectedUser.firstName + ' ' + selectedUser.lastName}</h5>
    ) : null;
})()}


               
              </div>

            </div>
            <div className="row">
            <h5 style={{ color: "#09AA29" }}>Status</h5>
             <div>
            
              <button style={{border:'none',color:'white',backgroundColor:ChoiceBraid && ChoiceBraid.statut==='available'?'#09AA29':ChoiceBraid && ChoiceBraid.statut==='out_of_stock'?colors.withGold:'red',padding:'10px',borderRadius:'8px',fontSize:'18px',fontWeight:'bold'}}>{ChoiceBraid && (ChoiceBraid.statut)}</button>
             </div>
            </div>
          </div>

          <footer>
            <div className="footerR">
              {ChoiceBraid && ChoiceBraid.pricePromo && (
                <>
                  <h5>Promotion</h5>
                  <h5 id="h5">
                    <strong style={{ color: colors.gold, fontSize: 18 }}>
                      {(
                        (1 - ChoiceBraid.pricePromo / ChoiceBraid.priceNormal) *
                        100
                      ).toFixed(2)}
                    </strong>{" "}
                    %
                  </h5>
                </>
              )}
            </div>
            <div className="bottomR">
              <div className="bottomR1">
                <div className="row">
                  <h4>Subtotal</h4>
                  {ChoiceBraid && (
                    <h4>
                      {ChoiceBraid.priceNormal}
                      <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                    </h4>
                  )}
                </div>
                <div className="row">
                  <h4>Tax</h4>
                  <h4>
                    00 <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                  </h4>
                </div>
                <div className="row">
                  {ChoiceBraid && ChoiceBraid.pricePromo && (
                    <>
                      <h4>Discount</h4>
                      <h4>
                        <strong style={{ color: colors.gold }}>
                          {ChoiceBraid.pricePromo - ChoiceBraid.priceNormal}
                        </strong>{" "}
                        <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                      </h4>
                    </>
                  )}
                </div>
                <div className="row">
                  <h4>
                    <strong style={{ fontFamily: "inter-bold" }}>
                      Total Amount
                    </strong>
                  </h4>
                  {ChoiceBraid &&
                    (ChoiceBraid.pricePromo ? (
                      <h4>
                        {ChoiceBraid.pricePromo}{" "}
                        <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                      </h4>
                    ) : (
                      <h4>
                        {ChoiceBraid.priceNormal}{" "}
                        <strong style={{ fontFamily: "inter-bold" }}>$</strong>
                      </h4>
                    ))}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent:'space-between',
                }}
              >
                <Btn
                  txtbtn={"Modify"}
                  bgcolor={"9, 170, 41"}
                  txtcolor={"white"}
                  iconbtn={procederIcon}
                  action={hideShowItem}
                />
                <Btn
                  txtbtn={"Delete"}
                  bgcolor={"255,0,0"}
                  txtcolor={"white"}
                  action={()=>handleDeleteBraidClick(ChoiceBraid)}
                />
              </div>
            </div>
          </footer>
          {confirmDeleteBraid && (
              <ConfirmDialog
                message={confirmDeleteBraid.message}
                onConfirm={confirmDeleteBraid.onConfirm}
                onCancel={cancelButton}
                texConfirm='YES'
                textCancel='NO'
              />
            )}
        </>
      )}
      {showItem2 && (
        <>
        <div className="column" style={{padding:'12px'}}>
          <div className="rowL" style={{ marginBottom: "16px" }}>
            <label style={{ color: colors.gold }}>Add Braid option</label>
          </div>
          <div className="row" style={{marginBottom:'16px' }}>
            <div style={{width:'35%'}}>
              <label for="name" >
                NAME<span style={{ color: "red" }}>*</span>
              </label>
              </div>
              <div style={{width:'60%'}}>
              <select
                value={braidName}
                onChange={(event) => handleBraidNameChange(event)}
              >
                {allTypes.map((type, index) => (
                  <option key={index} value={type.name}>
                    {type.name}
                  </option>
                ))}
              </select>
              </div>
              
            
          </div>
          <div className="row" style={{marginBottom:'16px'}}>
            <div style={{width:'35%'}}>
              <label for="name" >
                DESCRIPTION
              </label>
              </div>
              <div style={{width:'60%'}}>
              <input
                value={braidDescription}
                type="text"
                id="name"
                placeholder="categorie description"
                onChange={(event) => handleBraidDescriptionChange(event)}
              />
              </div>
             
            
          </div>
          <div className="row" style={{marginBottom:'16px'}}>
            <div style={{ width: "35%" }}>
              <label for="name" style={{ marginBottom: "16px" }}>
                Price Normal<span style={{ color: "red" }}>*</span>
              </label>
              </div>
              <div style={{width: '60%'}}>
              <input
                min={1}
                value={priceNormal}
                type="number"
                id="name"
                placeholder="240$"
                onChange={(event) => handlePriceNormalChange(event)}
              />
              </div>
           
          </div>
          <div className="row" style={{marginBottom:'16px'}}>
            <div  style={{ width: "35%" }}>
              <label for="name" style={{ marginBottom: "16px" }}>
                Time<span style={{ color: "red" }}>*</span>
              </label>
              </div>
              <div className="time-input-container" style={{width: '57%'}}>
                          <input
                            type="time"
                            id="start-time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            style={{ margin: "0 5px" }}
                          />
                          <div style={{display:'flex', flexDirection:'column'}}>
                          <button
                          style={style}
                            onClick={() =>
                              incrementTime(startTime, setStartTime)
                            }
                          >
                            ▲
                          </button>
                          <button
                          style={style}
                            onClick={() =>
                              decrementTime(startTime, setStartTime)
                            }
                          >
                            ▼
                          </button>
                          </div>
                           
                 </div>
          </div>
          <div className="rowS" style={{width:'100%',marginBottom:'16px'}}>
            <div className="form-group" style={{ width: "35%" }}>
              <label for="name" style={{ marginBottom: "16px" }}>
              EMPLOYERS<span style={{ color: "red" }}>*</span>
              </label>
              </div>
              <div style={{width: '60%'}}>
              <select value={user} onChange={(event)=>handleUserChange(event)}>
                  {users.length!== 0 &&
                    users.map((user,index)=>(
                      <option key={index} value={user.id} >{user.firstName+' '+ user.lastName}</option>
                    ))
                  }
                
                </select>
              </div>
           
          </div>
          
          <div className="rowS" style={{width:'100%',marginBottom:'16px'}}>
          <div className="form-group" style={{ width: "35%" }}>
              <label
                for="name"
                style={{ marginBottom: "16px", opacity: isPromo ? 1 : 0.4 }}
              >
                Price Promo<span style={{ color: "red" }}>*</span>
              </label>
              </div>
              <div style={{width:'60%'}}>
              <input
                min={1}
                type="number"
                placeholder="Promo Price"
                value={promoPrice}
                onChange={handlePromoPriceChange}
                disabled={!isPromo}
                style={{ opacity: isPromo ? 1 : 0.4 }}
              />
              </div>
             
           
          </div>
          <div className="rowS" style={{width:'100%',marginBottom:'16px'}}>
            <div className="form-group" style={{ width: "35%" }}>
              <label>
                <input
                  type="checkbox"
                  checked={isPromo}
                  onChange={handlePromoCheckboxChange}
                  value={isPromo}
                />
                Is Promo?
              </label>
            </div>
            <div className="column" style={{ width: "60%" }}>
              <label for="name">
                Statut<span style={{ color: "red" }}>*</span>
              </label>
              <div className="column">
                <div>
                  <input
                    type="checkbox"
                    value="available"
                    checked={selectedStatut === "available"}
                    onChange={() => handleSelectedStatutChange("available")}
                  />
                  Available
                </div>
                <div>
                  <input
                    type="checkbox"
                    value="out_of_stock"
                    checked={selectedStatut === "out_of_stock"}
                    onChange={() => handleSelectedStatutChange("out_of_stock")}
                  />
                  Out of stock
                </div>
                <div>
                  <input
                    type="checkbox"
                    value="discontinued"
                    checked={selectedStatut === "discontinued"}
                    onChange={() => handleSelectedStatutChange("discontinued")}
                  />
                  Discontinued
                </div>
              </div>
            </div>
          </div>
          <div className="rowL">
            <label htmlFor="imageUpload">
              Upload Image<span style={{ color: "red" }}>*</span>
              <input
                type="file"
                id="imageUpload"
                accept=".png, .jpg, .jpeg"
                onChange={(event) => handleImageUpload(event)}
                
              />
            </label>
          </div>
          {imagePreview &&  (
            <div className="image-preview">
              <img
                src={ (`data:image;base64,${imagePreview}`)}
                alt="Selected"
                style={{ maxWidth: "100%", maxHeight: "200px" }}
              />
            </div>
          )}
           </div>
          <footer>
          <div style={{marginTop:'auto',marginBottom:'16px'}}>
          <div className="rowL">
            <div className="button-container" onClick={handleUpdateType}>
              <button className="button" style={{backgroundColor:colors.gold}}>
                UPDATE
              </button>
            </div>
            <div className="button-container">
              <button
                className="button"
                style={{ marginLeft: "12px", backgroundColor: colors.withGold }}
                onClick={cancelButton}
              >
                CANCEL
              </button>
            </div>
          </div>
          </div>
          </footer>
          
        </>
        
       
      )}
       <ToastContainer limit={1} position="top-center" />
    </div>
  );
}

export default RightbarMenu;
